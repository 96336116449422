import React from "react";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import TextField from "cms/components/TextField";

const TextAreaForm = props => {
  const { content, classes, onContentChange, formSubmitted, regexValidation, ...others } = props;

  const { id, label, value } = content;

  const handleTextareaChange = e => {
    onContentChange({
      ...content,
      value: e.target.value
    });
  };

  return (
    <FormControl className={classes.formControl} key={id}>
      <TextField
        label={label}
        value={value}
        onChange={handleTextareaChange}
        multiline
        rows={3}
        className={classes.resize}
        {...others}
      />
    </FormControl>
  );
};

TextAreaForm.propTypes = {
  content: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
  formSubmitted: PropTypes.bool,
  regexValidation: PropTypes.string
};

TextAreaForm.defaultProps = {
  formSubmitted: false,
  regexValidation: null
};

export default TextAreaForm;
