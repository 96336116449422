import axios from "axios";
import generateCRUD from "cms/back-office/services/generateCRUD";
import { generateContentId, adminProperties, replacePagesByIds } from "cms/back-office/utils/adminContentsUtils";
import { removeProperties } from "cms/utils/commonUtils";

const name = "section-versions";

const SectionsCRUD = generateCRUD(name);

export default class AdminSectionsService extends SectionsCRUD {
  static getSectionVersions = ({ type, siteId }) =>
    axios.get("/getSectionVersions", { params: { type, siteId } }).then(r => r.data);

  static create = ({ contents = [], section }) => {
    const cleanContents = contents.map(c => removeProperties(c, adminProperties));
    const updatedIds = {};
    const contentsWithIds = cleanContents.map(c => generateContentId(c, updatedIds));
    const contentsWithPagesIds = contentsWithIds.map(content => replacePagesByIds(content, updatedIds));

    return SectionsCRUD.create({
      contents: contentsWithPagesIds,
      sectionId: section.id
    });
  };

  static update = ({ contents, versionName, id, updated }) => {
    const cleanContents = contents.map(c => removeProperties(c, adminProperties));
    const contentsWithIds = cleanContents.map(replacePagesByIds);

    return SectionsCRUD.update({
      id,
      updated,
      contents: contentsWithIds,
      versionName
    });
  };

  static updateStatus = (sectionVersion, status) =>
    axios
      .patch(`/api/${name}/${sectionVersion.id}/status`, { updated: sectionVersion.updated, status })
      .then(r => r.data);
}
