import React from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import versionStatuses from "cms/enums/versionStatuses";

const useStyles = makeStyles(theme => ({
  select: {
    fontWeight: 600,
    backgroundColor: theme.palette.secondary.main,
    "& .secondaryLabel": {
      display: "none"
    },
    "& .versionContainer": {
      textOverflow: "ellipsis",
      overflow: "hidden",
      maxWidth: 200
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent"
    }
  },
  menuItem: {
    "& .spacing": {
      width: "100%",
      padding: theme.spacing(0, 2)
    },
    "& .published": {
      color: theme.palette.success.main
    },
    "& .versionContainer": {
      padding: theme.spacing(1, 0),
      width: "100%",
      borderTop: `1px solid ${theme.palette.layout.border}`
    }
  },
  versionName: {
    color: theme.palette.secondary.text
  },
  versionUpdated: {
    fontSize: "0.9rem",
    color: theme.palette.layout.draft
  }
}));

const SelectVersion = props => {
  const { versions, selectedVersion, onSelectVersion } = props;

  const classes = useStyles();

  const handleSelectChange = e => {
    const id = e.target.value;
    if (selectedVersion.id !== id) {
      onSelectVersion(versions.find(version => version.id === id));
    }
  };

  const getDateFormated = data =>
    new Date(data).toLocaleDateString("FR-fr", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit"
    });

  const getAllDateFormated = data =>
    new Date(data).toLocaleDateString("FR-fr", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit"
    });

  const getVersionName = version => version.versionName || `Version du ${getDateFormated(version.created)}`;

  const getVersionUpdatedLabel = version => {
    const date = getAllDateFormated(version.updated);
    switch (version.status) {
      case versionStatuses.DRAFT:
        return `Dernière mise à jour le ${date}`;
      case versionStatuses.ARCHIVED:
        return `Archivée le ${date}`;
      case versionStatuses.PUBLISHED:
        return `Publiée le ${date}`;
      default:
        return "";
    }
  };

  return (
    <FormControl variant="outlined">
      {selectedVersion && (
        <Select
          className={classes.select}
          value={selectedVersion.id}
          onChange={handleSelectChange}
          IconComponent={KeyboardArrowDownIcon}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            }
          }}
        >
          {versions.map((version, index) => (
            <MenuItem key={version.id} value={version.id} style={{ padding: 0 }} className={classes.menuItem}>
              <div className="spacing">
                <div className="versionContainer" style={{ border: index === 0 && "none" }}>
                  <span
                    className={`${classes.versionName} ${version.status === "published" ? "published" : ""}`}
                    title={getVersionName(version)}
                  >
                    {getVersionName(version)}
                  </span>
                  <br />
                  <span className={`secondaryLabel ${classes.versionUpdated}`}>{getVersionUpdatedLabel(version)}</span>
                </div>
              </div>
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
};

SelectVersion.propTypes = {
  versions: PropTypes.arrayOf(PropTypes.shape()),
  selectedVersion: PropTypes.shape(),
  onSelectVersion: PropTypes.func.isRequired
};

SelectVersion.defaultProps = {
  versions: [],
  selectedVersion: null
};

export default SelectVersion;
