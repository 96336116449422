import React, { Fragment, useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@mui/styles";
import AdminMessageContext from "cms/back-office/components/AdminMessageContext";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  LinearProgress,
  TextField,
  Typography
} from "@mui/material";
import Modal, { ModalActions } from "cms/back-office/components/Modal";
import NodesPicker from "da-ged-web/components/NodesListing/NodesPicker";
import { FILE } from "da-ged-web/models/Node";
import FileService from "cms/gedServices/FileService";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import themeGED from "cms/back-office/theme/themeGED";
import Cancel from "@mui/icons-material/Cancel";
import { urlRegex } from "cms/utils/commonUtils";
import SelectForm from "cms/back-office/components/contentForms/SelectForm";
import ImageFiltersForm from "cms/back-office/components/contentForms/ImageFiltersForm";
import { getFilterStyle } from "cms/utils/imageFiltersUtil";
import generateTemplateProps from "cms/utils/templatePropsUtils";

const useStyles = makeStyles(theme => ({
  fieldsetCustom: {
    border: `1px solid ${theme.palette.form.border}`,
    borderRadius: 4,
    " & legend": {
      fontSize: "1rem"
    }
  },
  filePickerActions: {
    display: "flex",
    flexDirection: "row",
    "& hr": {
      height: 38,
      margin: theme.spacing(0, 1)
    }
  },
  filePickerBtn: {
    borderRadius: 5,
    flex: 1
  },
  inlineFormControl: {
    flex: 2,
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center"
  },
  inlineLabel: {
    color: theme.palette.typography.default,
    fontWeight: 600,
    fontSize: "0.9rem",
    whiteSpace: "nowrap",
    marginRight: theme.spacing(1)
  },
  inlineInput: {
    width: "100%"
  },
  delete: {
    cursor: "pointer",
    marginLeft: theme.spacing(1)
  }
}));

const FilePicker = props => {
  const {
    onSelectFile,
    file,
    image,
    formSubmitted,
    pageVersion,
    required,
    positionChild,
    canChangePosition,
    imageFiltersChild,
    classes: defaultClasses,
    disableAccessibility
  } = props;
  const { url } = file || {};

  const classes = useStyles();
  const theme = useTheme();

  const { displayError } = useContext(AdminMessageContext);

  const [selectedFile, setSelectedFile] = useState(null);
  const [publishing, setPublishing] = useState(false);
  const [errors, setErrors] = useState({});

  const [uploaderId] = useState(`uploader-${Math.floor(Math.random() * 100000)}`);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);

  const [gedModalOpen, setGedModalOpen] = useState(false);
  const [title, setTitle] = useState(file && file.title ? file.title : "");
  const [alt, setAlt] = useState(file && file.alt ? file.alt : "");
  const [urlValue, setUrlValue] = useState("");

  useEffect(() => {
    setErrors({
      title: image && url && !title && !disableAccessibility,
      alt: image && url && !alt && !disableAccessibility
    });
  }, [title, alt, url, image, disableAccessibility]);

  const handleClickGed = () => {
    setGedModalOpen(true);
  };

  const handleClickUpload = () => {
    document.getElementById(uploaderId).click();
  };

  const publishFile = f => {
    setPublishing(true);
    return FileService.publish({ file: f, pageVersion }).then(response => {
      setPublishing(false);
      return response.data;
    });
  };

  React.useEffect(() => {
    if (uploadedFile) {
      onSelectFile(uploadedFile);
      setUploadedFile(null);
    }
  }, [onSelectFile, uploadedFile]);

  const handleUploadFile = e => {
    const fileToUpload = e.target.files[0];
    if (fileToUpload) {
      setUploadProgress(null);
      const options = {
        onUploadProgress: progressEvent => {
          const percentCompleted = (progressEvent.loaded * 100) / progressEvent.total;
          if (percentCompleted < 100) {
            setUploadProgress(percentCompleted);
          }
        }
      };
      FileService.createFile({ file: fileToUpload }, options)
        .then(response => {
          const { data: uploadedFile } = response;
          publishFile(uploadedFile)
            .then(publishedFile => {
              setUploadedFile({
                ...uploadedFile,
                url: publishedFile.publicUrl,
                alt,
                title
              });
              setUploadProgress(null);
            })
            .catch(() => {
              setUploadProgress(null);
              displayError("Erreur lors de la publication du fichier");
            });
        })
        .catch(error => {
          setUploadProgress(null);
          displayError(error && error.message);
        });
    }
  };

  const handleSelectNode = node => {
    if (node && node.type === FILE) {
      setSelectedFile(node);
    } else {
      setSelectedFile(null);
    }
  };

  const handleClickValidateFile = () => {
    if (selectedFile) {
      publishFile(selectedFile).then(publishedFile => {
        onSelectFile({
          ...selectedFile,
          url: publishedFile.publicUrl,
          alt,
          title
        });
        setGedModalOpen(false);
      });
    }
  };

  const handleInputFileUrl = e => {
    setUrlValue(e.target.value);
    onSelectFile({
      url: e.target.value,
      title,
      alt
    });
  };

  const handleTitleChange = e => {
    setTitle(e.target.value);
    onSelectFile({
      ...file,
      title: e.target.value
    });
  };

  const handleAltChange = e => {
    setAlt(e.target.value);
    onSelectFile({
      ...file,
      alt: e.target.value
    });
  };

  const handleDeleteFile = () => {
    setTitle("");
    setAlt("");
    setUrlValue("");
    onSelectFile(null);
  };

  const handlePositionChange = newPosition => {
    const { value } = newPosition || {};
    onSelectFile({
      ...file,
      position: value
    });
  };

  const handleImageFiltersChange = newFilters => {
    onSelectFile({
      ...file,
      filters: newFilters
    });
  };

  const showError = key => formSubmitted && errors[key];

  const showImage = image && file && file.url && (!urlValue || (urlValue && urlValue.match(urlRegex)));
  const showFile = !image && file && file.url;

  const urlValid = !urlValue || (formSubmitted && urlValue && urlValue.match(urlRegex));

  return (
    <Fragment>
      <input type="file" id={uploaderId} onChange={handleUploadFile} style={{ display: "none" }} />
      <div className={classes.filePickerActions}>
        <Button
          className={classes.filePickerBtn}
          color="secondary"
          variant="contained"
          size="small"
          onClick={handleClickUpload}
          style={{ color: theme.palette.form.text }}
        >
          Depuis mon ordinateur
        </Button>
        <Divider orientation="vertical" />
        <Button
          className={classes.filePickerBtn}
          color="secondary"
          variant="contained"
          size="small"
          onClick={handleClickGed}
          style={{ color: theme.palette.form.text }}
        >
          Depuis la médiathèque
        </Button>
        <Divider orientation="vertical" />
        <FormControl className={classes.inlineFormControl}>
          <span className={classes.inlineLabel}>Depuis une URL</span>
          <Input className={classes.inlineInput} onChange={handleInputFileUrl} value={urlValue} />
        </FormControl>
      </div>

      {required && formSubmitted && (!file || !file.url) && (
        <FormHelperText error>Veuillez sélectionner {image ? "une image" : "un fichier"}</FormHelperText>
      )}
      {formSubmitted && !urlValid && <FormHelperText error>URL invalide</FormHelperText>}
      {uploadProgress && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ width: "100%", mr: 1 }}>
            <LinearProgress variant="determinate" value={uploadProgress} />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2">{`${Math.round(uploadProgress)}%`}</Typography>
          </Box>
        </Box>
      )}
      {!uploadProgress && showImage && (
        <>
          <Box mt={2} mb={2} style={{ position: "relative", textAlign: "center" }}>
            <img
              width={250}
              src={file && file.url}
              alt="file"
              style={{
                filter: getFilterStyle(generateTemplateProps(imageFiltersChild?.children))
              }}
            />
            <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
              {file.name} <Cancel className={classes.delete} onClick={handleDeleteFile} />
            </Box>
          </Box>

          {canChangePosition && (
            <Box mt={2} mb={2}>
              <SelectForm
                content={{ ...positionChild, value: positionChild.value || "center" }}
                onContentChange={handlePositionChange}
                classes={defaultClasses}
              />
            </Box>
          )}

          {imageFiltersChild && (
            <Box mt={2} mb={2}>
              <ImageFiltersForm
                content={imageFiltersChild}
                onContentChange={handleImageFiltersChange}
                classes={defaultClasses}
              />
            </Box>
          )}

          {!disableAccessibility && (
            <fieldset className={classes.fieldsetCustom}>
              <legend>Référencement / accessibilité</legend>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    error={showError("title")}
                    label="Titre de l'image"
                    value={title}
                    name="title"
                    onChange={handleTitleChange}
                    helperText={showError("title") && "Veuillez saisir le titre de l'image"}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={showError("alt")}
                    label="Texte alternatif"
                    value={alt}
                    name="alt"
                    onChange={handleAltChange}
                    helperText={showError("alt") && "Veuillez saisir un texte alternatif à l'image"}
                  />
                </Grid>
              </Grid>
            </fieldset>
          )}
        </>
      )}

      {!uploadProgress && showFile && (
        <Box mt={2} mb={2}>
          <Box display="inline-block" fontWeight="bold">
            Document:
          </Box>{" "}
          {file.name || file.url}
        </Box>
      )}

      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themeGED}>
          <Modal
            aria-labelledby="select-file"
            aria-describedby="select-file-from-library"
            open={gedModalOpen}
            onClose={() => setGedModalOpen(false)}
            size="lg"
          >
            <div>
              <div style={{ height: "75vh" }}>
                <NodesPicker
                  tableProps={{
                    rowHeight: 35
                  }}
                  onSelectionChange={handleSelectNode}
                />
              </div>
              <ModalActions>
                <Button color="secondary" onClick={() => setGedModalOpen(false)}>
                  Annuler
                </Button>
                <Button color="primary" onClick={handleClickValidateFile} disabled={!selectedFile || publishing}>
                  Valider
                </Button>
              </ModalActions>
            </div>
          </Modal>
        </ThemeProvider>
      </StyledEngineProvider>
    </Fragment>
  );
};

FilePicker.propTypes = {
  onSelectFile: PropTypes.func.isRequired,
  file: PropTypes.shape(),
  pageVersion: PropTypes.shape(),
  image: PropTypes.bool,
  formSubmitted: PropTypes.bool,
  required: PropTypes.bool,
  canChangePosition: PropTypes.bool,
  positionChild: PropTypes.shape()
};

FilePicker.defaultProps = {
  file: null,
  pageVersion: null,
  image: false,
  formSubmitted: false,
  required: false,
  canChangePosition: false,
  positionChild: null,
  disableAccessibility: false
};

export default FilePicker;
