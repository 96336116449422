var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import ManageAreas from "./ManageAreas";
var ManageAreasPage = function () {
    return (_jsxs(Box, __assign({ sx: { height: "100%", p: 4, bgcolor: "layout.background", overflow: "auto", boxSizing: "border-box" } }, { children: [_jsx(Typography, __assign({ variant: "h1", sx: { mb: 4 } }, { children: "Gestion des territoires" }), void 0), _jsx(Box, __assign({ sx: { width: "100%" } }, { children: _jsx(ManageAreas, {}, void 0) }), void 0)] }), void 0));
};
export default ManageAreasPage;
