import React from "react";
import { Route, withRouter } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import Icon from "cms/editableComponents/Icon";
import { SideBarContext } from "cms/back-office/components/Layout/SideBar";
import ManageEstablishmentsPage from "./ManageEstablishmentsPage";
import ManageAreasPage from "./ManageAreasPage";

const establishmentsPath = "/etablissements";
const areasPath = "/territoires";

const Routes = withRouter(props => {
  const { history, adminWebsitePath } = props;

  const { addItems: addSideBarItems, removeItems: removeSideBarItems } = React.useContext(SideBarContext);

  const { keycloak } = useKeycloak();

  const canReadEstablishment = keycloak.hasResourceRole("read_establishment", "arpavie-api");

  const canReadAreas = keycloak.hasResourceRole("read_area", "arpavie-api");

  React.useEffect(() => {
    const items = [];
    if (canReadEstablishment) {
      items.push({
        icon: <Icon icon="cogs" />,
        label: "Établissements",
        title: "Administrer les établissements",
        onClick: () => history.push(establishmentsPath),
        order: 2
      });
    }
    if (canReadAreas) {
      items.push({
        icon: <Icon icon="map-marked" />,
        label: "Territoires",
        title: "Administrer les territoires",
        onClick: () => history.push(areasPath),
        order: 2.5
      });
    }
    addSideBarItems(items);
    return () => {
      removeSideBarItems(items);
    };
  }, [addSideBarItems, adminWebsitePath, canReadAreas, canReadEstablishment, history, removeSideBarItems]);

  return [
    <Route path={establishmentsPath} exact key="establishments">
      <ManageEstablishmentsPage />
    </Route>,
    <Route path={areasPath} exact key="areas">
      <ManageAreasPage />
    </Route>
  ];
});

export default Routes;
