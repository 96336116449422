import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Box, Grid, InputAdornment, Popover, TextField, Typography } from "@mui/material";
import SimpleBar from "simplebar-react";
import Icon from "cms/editableComponents/Icon";
import ThemeBackOfficeProvider, { ThemeContext } from "cms/back-office/theme/ThemeBackOfficeProvider";
import categories, { getCategoryLabel } from "cms/enums/categoriesContents";
import { CobaltIcon } from "@docaposte-agility/da-design-system";

const ContentIcon = props => {
  const { content } = props;
  const { icon, IconComponent } = content;

  const { themeBackOffice: theme } = useContext(ThemeContext);

  if (IconComponent) {
    return (
      <IconComponent
        color={theme.palette.layout.contentBtnBackground}
        backgroundColor={theme.palette.layout.contentBtnColor}
      />
    );
  }
  if (icon) {
    return <Icon icon={content.icon} type="fas" />;
  }
  return null;
};

const ContentItem = props => {
  const { content, onClick } = props;
  const { label } = content;

  return (
    <Box
      sx={{
        p: 1,
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        alignItems: "center",
        textAlign: "center",
        "&:hover": {
          bgcolor: "secondary.dark"
        }
      }}
      onClick={onClick}
    >
      <Box sx={{ width: 24, height: 24, fontSize: 24, mb: 1 }}>
        <ContentIcon content={content} />
      </Box>
      <Typography variant="body2">{label}</Typography>
    </Box>
  );
};

const SelectContentModal = props => {
  const { open, contents, onSelectContent, onClose, target } = props;

  const [filter, setFilter] = React.useState("");

  const handleChangeFilter = React.useCallback(e => {
    setFilter(e.target.value);
  }, []);

  React.useEffect(() => {
    if (!open) {
      setFilter("");
    }
  }, [open]);

  const filteredContents = React.useMemo(() => {
    return contents.filter(
      content =>
        (content?.category?.toLowerCase() || "").includes(filter.toLowerCase()) ||
        (content?.label?.toLowerCase() || "").includes(filter.toLowerCase())
    );
  }, [contents, filter]);

  const handleFilterKeyDown = React.useCallback(
    e => {
      if (e.key === "Enter" && filteredContents.length === 1) {
        onSelectContent(filteredContents[0]);
      }
    },
    [filteredContents, onSelectContent]
  );

  const getContentsByCategory = React.useCallback(
    category => filteredContents.filter(content => (category ? content.category === category : !content.category)),
    [filteredContents]
  );

  return (
    <ThemeBackOfficeProvider>
      <Popover
        open={open}
        anchorEl={target}
        onClose={onClose}
        anchorReference={target ? "anchorEl" : "anchorPosition"}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        anchorPosition={{ top: 200, left: 400 }}
      >
        <Box
          sx={{
            width: 500,
            p: 2
          }}
        >
          <TextField
            placeholder="Filtrer"
            fullWidth
            autoFocus
            value={filter}
            onChange={handleChangeFilter}
            onKeyDown={handleFilterKeyDown}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CobaltIcon name="search" />
                </InputAdornment>
              ),
              endAdornment: filter && (
                <InputAdornment position="end" onClick={() => setFilter("")} sx={{ cursor: "pointer" }}>
                  <CobaltIcon name="x" />
                </InputAdornment>
              )
            }}
            sx={{ mb: 2 }}
          />
          <SimpleBar style={{ maxHeight: 600 }}>
            {[categories.STRUCTURE, "", categories.SPECIFIC].map(categoryName => {
              const contentsList = getContentsByCategory(categoryName);
              if (contentsList.length === 0) {
                return null;
              }
              return (
                <Box sx={{ mb: 2 }} key={categoryName}>
                  <Typography variant="h6" sx={{ mb: 1 }}>
                    {getCategoryLabel(categoryName)}
                  </Typography>
                  <Grid container rowSpacing={1}>
                    {contentsList.map(content => (
                      <Grid item xs={3} key={content.type}>
                        <ContentItem content={content} onClick={() => onSelectContent(content)} />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              );
            })}
          </SimpleBar>
        </Box>
      </Popover>
    </ThemeBackOfficeProvider>
  );
};

SelectContentModal.propTypes = {
  open: PropTypes.bool,
  contents: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSelectContent: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

SelectContentModal.defaultProps = {
  open: false
};

export default SelectContentModal;
