var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useQueryClient } from "react-query";
import AdminContext from "cms/back-office/components/AdminContext";
import AreaForm from "bo/AreaForm";
import { useAreaUpdate, entityName } from "services/area.service";
var EditArea = function (props) {
    var area = props.area, onValidate = props.onValidate, onCancel = props.onCancel;
    var id = area.id;
    var _a = React.useContext(AdminContext), displaySuccess = _a.displaySuccess, displayError = _a.displayError;
    var queryClient = useQueryClient();
    var updateArea = useAreaUpdate({
        onSuccess: function () {
            queryClient.invalidateQueries(entityName);
            // @ts-ignore
            displaySuccess("Territoire mis à jour");
            onValidate();
        },
        onError: function () {
            // @ts-ignore
            displayError("Erreur lors de la modification du territoire");
        }
    }).mutate;
    var handleValidateArea = function (values) {
        updateArea(__assign(__assign({}, values), { id: id }));
    };
    return _jsx(AreaForm, { area: area, onSubmit: handleValidateArea, onCancel: onCancel }, void 0);
};
export default EditArea;
