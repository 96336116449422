import React from "react";
import InfoBlock from "cms/editableComponents/InfoBlock";
import Text from "cms/editableComponents/Text";

const text = "Zone de texte libre";

const InfoBlockPreview = () => {
  return <InfoBlock text={<Text>{text}</Text>} icon={{ icon: "info", type: "fas" }} />;
};

export default InfoBlockPreview;
