import React from "react";
import PropTypes from "prop-types";
import EditIcon from "@mui/icons-material/Settings";
import AdminContentModal from "cms/back-office/components/AdminContentModal";

const EditContentAction = props => {
  const { content, onContentChange } = props;

  const [displayModalContent, setDisplayModalContent] = React.useState(false);

  const handleClickEdit = () => {
    setDisplayModalContent(true);
  };

  const handleContentChange = updatedContent => {
    setDisplayModalContent(false);
    onContentChange(updatedContent);
  };

  return (
    <>
      <EditIcon onClick={handleClickEdit} />
      <AdminContentModal
        open={displayModalContent}
        content={content}
        onValidate={handleContentChange}
        onClose={() => setDisplayModalContent(false)}
      />
    </>
  );
};

EditContentAction.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired
};

export default EditContentAction;
