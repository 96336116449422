import React, { Fragment } from "react";
import PropTypes from "prop-types";
import contentsTypes from "cms/enums/contentsTypes";
import AdminListForm from "cms/back-office/components/contentForms/AdminListForm";
import InputForm from "cms/back-office/components/contentForms/InputForm";
import ImageForm from "cms/back-office/components/contentForms/ImageForm";

const TabsForm = props => {
  const { content, onContentChange, classes } = props;
  const { label, children, type } = content;
  const tabsTypes = [contentsTypes.TAB, contentsTypes.TAB_CARDS];

  const objectsChildren = children.filter(c => tabsTypes.includes(c.type)) || [];

  const handleChildChange = (oldChild, newChild) => {
    onContentChange({
      ...content,
      children: children.map(c => (c === oldChild ? newChild : c))
    });
  };

  const handleChildrenChange = (oldChildren, newChildren) => {
    onContentChange({
      ...content,
      children: newChildren
    });
  };

  return (
    <Fragment>
      <h2 className={classes.title}>{label}</h2>

      <AdminListForm
        inline
        contents={objectsChildren}
        elementsType={type === contentsTypes.TABS ? contentsTypes.TAB : contentsTypes.TAB_CARDS}
        elementsKey="tabs"
        onContentsChange={newContents => handleChildrenChange(objectsChildren, newContents)}
      >
        {objectsChildren.map(currentObject => (
          <div key={currentObject.id}>
            <InputForm
              content={{
                ...currentObject.children.find(c => c.key === "title")
              }}
              onContentChange={newContent =>
                handleChildChange(currentObject, {
                  ...currentObject,
                  children: (currentObject.children || []).map(c => (c.key === "title" ? newContent : c))
                })
              }
              classes={classes}
            />
            <ImageForm
              content={{
                ...currentObject.children.find(c => c.key === "image")
              }}
              onContentChange={newContent =>
                handleChildChange(currentObject, {
                  ...currentObject,
                  children: (currentObject.children || []).map(c => (c.key === "image" ? newContent : c))
                })
              }
              classes={classes}
            />
          </div>
        ))}
      </AdminListForm>
    </Fragment>
  );
};

TabsForm.propTypes = {
  content: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired
};

export default TabsForm;
