import React, { useContext } from "react";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import FilePicker from "cms/back-office/components/FilePicker";
import AdminWebsiteContext from "cms/back-office/components/AdminWebsiteContext";

const FileForm = props => {
  const { content, classes, onContentChange, image, formSubmitted, required } = props;

  const { id, label, value, children } = content;

  const imageFiltersChild = children.find(c => c.key === "filters");

  const { currentPageVersion } = useContext(AdminWebsiteContext);

  const handleSelectFile = file => {
    onContentChange({
      ...content,
      value: JSON.stringify(file)
    });
  };

  return (
    <FormControl className={classes.formControl} key={id}>
      <label>{label}</label>
      <FilePicker
        onSelectFile={handleSelectFile}
        pageVersion={currentPageVersion}
        image={image}
        file={value && JSON.parse(value)}
        required={required}
        formSubmitted={formSubmitted}
        imageFiltersChild={imageFiltersChild}
      />
    </FormControl>
  );
};

FileForm.propTypes = {
  content: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
  image: PropTypes.bool,
  formSubmitted: PropTypes.bool,
  required: PropTypes.bool
};

FileForm.defaultProps = {
  image: false,
  formSubmitted: false,
  required: false
};

export default FileForm;
