import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { adminProperties, replacePagesByIds } from "cms/back-office/utils/adminContentsUtils";
import { removeProperties } from "cms/utils/commonUtils";
import ThemeBackOfficeProvider from "cms/back-office/theme/ThemeBackOfficeProvider";
import AdminContentModal from "cms/back-office/components/AdminContentModal";
import CmsHooks, { HOOKS } from "cms/utils/CmsHooks";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";

const useStyles = makeStyles(theme => ({
  buttons: {
    marginBottom: theme.spacing(2),
    "& button": {
      marginRight: theme.spacing(2),
      padding: "12px 24px",
      "& span": {
        padding: 0
      }
    }
  },
  icon: {
    marginRight: theme.spacing(1)
  }
}));

const AdminTemplateSettings = props => {
  const {
    templateSettings,
    handleChangeTemplateSettings,
    displayModal,
    setDisplayModal,
    handleValidateTemplateSettingsChange,
    handleCancelTemplateSettingsChange,
    templateIsModify,
    setTemplateIsModify
  } = props;

  const classes = useStyles();

  const templates = CmsHooks.getHook(HOOKS.TEMPLATES) || [];
  const template = (templateSettings && templates.find(t => t.key === templateSettings.key)) || {};
  const templateLabel = template.label;

  const handleContentChange = updatedContent => {
    setDisplayModal(false);
    setTemplateIsModify(true);
    handleChangeTemplateSettings(updatedContent);
  };

  const handleValidate = () => {
    setTemplateIsModify(false);
    templateSettings.children = templateSettings.children.map(c => removeProperties(c, adminProperties));
    templateSettings.children = templateSettings.children.map(content => replacePagesByIds(content));
    handleValidateTemplateSettingsChange(templateSettings);
  };

  const handleCancel = () => {
    setTemplateIsModify(false);
    handleCancelTemplateSettingsChange();
  };

  if (!templateSettings || !templateSettings.children) {
    return null;
  }

  return (
    <ThemeBackOfficeProvider>
      <div className={classes.buttons}>
        <Button color="secondary" variant="contained" onClick={() => setDisplayModal(true)}>
          <Icon className={`${classes.icon} fas fa-cog`} /> Modifier le gabarit
        </Button>
        {templateIsModify && (
          <>
            <Button color="primary" variant="contained" onClick={handleCancel}>
              Annuler
            </Button>
            <Button color="primary" variant="contained" onClick={handleValidate}>
              Valider pour toutes les pages
            </Button>
          </>
        )}
      </div>

      <AdminContentModal
        title={`Paramètres du gabarit: ${templateLabel}`}
        open={displayModal}
        content={templateSettings}
        onValidate={handleContentChange}
        onClose={() => setDisplayModal(false)}
      />
    </ThemeBackOfficeProvider>
  );
};

AdminTemplateSettings.propTypes = {
  templateSettings: PropTypes.shape(),
  handleChangeTemplateSettings: PropTypes.func.isRequired,
  displayModal: PropTypes.bool.isRequired,
  setDisplayModal: PropTypes.func.isRequired,
  handleValidateTemplateSettingsChange: PropTypes.func.isRequired,
  handleCancelTemplateSettingsChange: PropTypes.func.isRequired,
  templateIsModify: PropTypes.bool.isRequired,
  setTemplateIsModify: PropTypes.func.isRequired
};
AdminTemplateSettings.defaultProps = {
  templateSettings: null
};

export default AdminTemplateSettings;
