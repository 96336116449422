import React from "react";
import wrappable from "cms/utils/wrappable";
import MuiSelect from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const Select = wrappable(props => {
  return <MuiSelect IconComponent={KeyboardArrowDownIcon} {...props} />;
});

export default Select;
