import React from "react";
import PropTypes from "prop-types";
import TextAreaForm from "cms/back-office/components/contentForms/TextAreaForm";
import { encodeCodeBlockText, decodeCodeBlockText } from "cms/utils/commonUtils";

const CodeBlockForm = props => {
  const { content, classes, onContentChange, ...others } = props;

  const { value } = content;

  const handleCodeBlockTextChange = code => {
    const { value } = code;
    onContentChange({
      ...content,
      value: encodeCodeBlockText(value)
    });
  };

  return (
    <TextAreaForm
      content={content}
      onContentChange={handleCodeBlockTextChange}
      rows={15}
      classes={classes}
      {...others}
      value={decodeCodeBlockText(value)}
    />
  );
};

CodeBlockForm.propTypes = {
  content: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
  formSubmitted: PropTypes.bool,
  regexValidation: PropTypes.string
};

CodeBlockForm.defaultProps = {
  formSubmitted: false,
  regexValidation: null
};

export default CodeBlockForm;
