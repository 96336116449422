import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import InputForm from "cms/back-office/components/contentForms/InputForm";
import ColorForm from "cms/back-office/components/contentForms/ColorForm";
import SelectForm from "cms/back-office/components/contentForms/SelectForm";
import Link from "cms/editableComponents/Link";

const IconForm = props => {
  const { content, classes, onContentChange } = props;

  const { id, children } = content;

  const typeChild = children.find(c => c.key === "type");
  const iconChild = children.find(c => c.key === "icon");
  const colorChild = children.find(c => c.key === "color");
  const backgroundColorChild = children.find(c => c.key === "background");

  const handleChildChange = oldChild => newChild => {
    onContentChange({
      ...content,
      children: children.map(child => {
        if (child === oldChild) {
          return newChild;
        }
        return child;
      })
    });
  };

  return (
    <fieldset key={id} className={classes.fieldset}>
      <legend>Icône</legend>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <InputForm content={iconChild} onContentChange={handleChildChange(iconChild)} classes={classes} />
        </Grid>
        <Grid item xs={6}>
          <SelectForm content={typeChild} onContentChange={handleChildChange(typeChild)} classes={classes} />
        </Grid>
        <Grid item xs={6}>
          <ColorForm content={colorChild} onContentChange={handleChildChange(colorChild)} classes={classes} />
        </Grid>
        <Grid item xs={6}>
          <ColorForm
            content={backgroundColorChild}
            onContentChange={handleChildChange(backgroundColorChild)}
            classes={classes}
          />
        </Grid>
      </Grid>
      <Link
        url="https://fontawesome.com/icons?d=gallery&s=light"
        external
        underline="always"
        color="primary"
        style={{ fontSize: "0.9rem" }}
      >
        Voir la liste des icônes
      </Link>
    </fieldset>
  );
};

IconForm.propTypes = {
  content: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired
};

export default IconForm;
