import React, { Fragment } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Icon from "cms/editableComponents/Icon";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { getDefaultContentByType, generateContentId } from "cms/back-office/utils/adminContentsUtils";
import { swapArrayItems } from "cms/utils/ArrayUtils";

const useStyles = makeStyles(() => ({
  child: props => ({
    display: "flex",
    flexDirection: props.inline ? "row" : "column",
    justifyContent: props.inline ? "space-between" : "flex-start",
    alignItems: props.inline ? "center" : "stretch"
  }),
  actions: props => ({
    alignSelf: props.inline ? "auto" : "flex-end"
  })
}));

const AdminListForm = props => {
  const { contents, elementsType, elementsKey, onContentsChange, inline, children } = props;
  const classes = useStyles({ inline });

  const handleMove = (childId, up = false) => {
    const contentToMove = contents.find(c => c.id === childId);
    const contentIndex = contents.indexOf(contentToMove);
    const targetIndex = up ? contentIndex - 1 : contentIndex + 1;
    onContentsChange(swapArrayItems(contents, contentIndex, targetIndex));
  };

  const handleClickDelete = childId => {
    onContentsChange([...contents.filter(c => c.id !== childId)]);
  };

  const handleClickAdd = () => {
    const defaultContent = getDefaultContentByType(elementsType);
    onContentsChange([...contents, { key: elementsKey, ...generateContentId(defaultContent) }]);
  };

  return (
    <Fragment>
      {React.Children.map(
        children,
        (child, index) =>
          child && (
            <div className={classes.child} key={child.key}>
              <div style={{ flex: 1 }}>{child}</div>
              <div className={classes.actions}>
                <IconButton
                  onClick={() => handleMove(child.key, true)}
                  edge="end"
                  aria-label="monter"
                  disabled={index === 0}
                  size="large"
                >
                  <Icon icon="chevron-up" title="Monter" />
                </IconButton>
                <IconButton
                  onClick={() => handleMove(child.key)}
                  edge="end"
                  aria-label="descendre"
                  disabled={index === children.length - 1}
                  size="large"
                >
                  <Icon icon="chevron-down" title="Descendre" />
                </IconButton>
                <IconButton onClick={() => handleClickDelete(child.key)} edge="end" aria-label="supprimer" size="large">
                  <Icon icon="trash" title="Supprimer" />
                </IconButton>
              </div>
            </div>
          )
      )}
      {elementsType && <Button onClick={handleClickAdd}>Ajouter</Button>}
    </Fragment>
  );
};

AdminListForm.propTypes = {
  contents: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  elementsType: PropTypes.string,
  elementsKey: PropTypes.string,
  onContentsChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  inline: PropTypes.bool
};

AdminListForm.defaultProps = {
  inline: false,
  elementsType: null,
  elementsKey: null
};

export default AdminListForm;
