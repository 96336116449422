var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { Box, Typography, Table, TableBody, TableCell, TableSortLabel, TableRow, TableHead, IconButton } from "@mui/material";
import Icon from "cms/editableComponents/Icon";
import Modal from "cms/back-office/components/Modal";
import Loader from "cms/back-office/components/Loader";
import ThemeBackOfficeProvider from "cms/back-office/theme/ThemeBackOfficeProvider";
import { useAreaList } from "services/area.service";
import CreateArea from "bo/CreateArea";
import EditArea from "bo/EditArea";
var ManageAreas = function () {
    var areasPagination = useAreaList().data;
    var _a = (areasPagination || {}).data, areas = _a === void 0 ? [] : _a;
    var keycloak = useKeycloak().keycloak;
    var canUpdateAreas = keycloak.hasResourceRole("update_area", "arpavie-api");
    var _b = useState(), currentArea = _b[0], setCurrentArea = _b[1];
    var _c = useState(false), openAreaFormModal = _c[0], setOpenAreaFormModal = _c[1];
    var handleOpenEditAreaModal = function (area) {
        setCurrentArea(area);
        setOpenAreaFormModal(true);
    };
    var handleCloseAreaFormModal = React.useCallback(function () {
        setCurrentArea();
        setOpenAreaFormModal(false);
    }, []);
    var headCells = [
        { id: "name", sortable: true, label: "Nom", render: function (row) { return row.name; } }
    ];
    if (canUpdateAreas) {
        headCells.push({
            id: "edit",
            label: "",
            render: function (row) {
                return (_jsx(_Fragment, { children: _jsx(IconButton, __assign({ sx: { p: 0.5, fontSize: "1.1rem" }, onClick: function () { return handleOpenEditAreaModal(row); } }, { children: _jsx(Icon, { icon: "pen", title: "Modifier le territoire" }, void 0) }), void 0) }, void 0));
            }
        });
    }
    return (_jsxs(_Fragment, { children: [!areas && (_jsx(Box, __assign({ sx: { my: 2 } }, { children: _jsx(Loader, {}, void 0) }), void 0)), areas && areas.length > 0 ? (_jsx(_Fragment, { children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsx(TableRow, { children: headCells.map(function (headCell) { return (_jsx(TableCell, __assign({ sx: { fontWeight: 600, fontSize: "1rem" } }, { children: _jsx(TableSortLabel, __assign({ hideSortIcon: true, sx: {
                                            color: "inherit",
                                            lineHeight: "normal"
                                        } }, { children: headCell.label }), void 0) }), headCell.id)); }) }, void 0) }, void 0), _jsx(TableBody, { children: areas.map(function (data) { return (_jsx(TableRow, { children: headCells.map(function (attribut, index) { return (_jsx(TableCell, { children: attribut.render(data) }, "" + (attribut + index.toString()))); }) }, data.id)); }) }, void 0)] }, void 0) }, void 0)) : (areas && (_jsx(Typography, __assign({ variant: "h5", sx: { fontSize: "1rem", fontWeight: 600, my: 2 } }, { children: "Aucun territoire trouv\u00E9" }), void 0))), _jsx(ThemeBackOfficeProvider, { children: _jsx(Modal
                // @ts-ignore
                , __assign({ 
                    // @ts-ignore
                    open: openAreaFormModal, size: "md", title: (currentArea ? "Modifier" : "Ajouter") + " un territoire", onClose: handleCloseAreaFormModal }, { children: currentArea ? (_jsx(EditArea, { area: currentArea, onValidate: handleCloseAreaFormModal, onCancel: handleCloseAreaFormModal }, void 0)) : (_jsx(CreateArea, { onValidate: handleCloseAreaFormModal, onCancel: handleCloseAreaFormModal }, void 0)) }), void 0) }, void 0)] }, void 0));
};
export default ManageAreas;
