import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import contentsTypes from "cms/enums/contentsTypes";
import CheckboxForm from "cms/back-office/components/contentForms/CheckboxForm";
import RadioGroupForm from "cms/back-office/components/contentForms/RadioGroupForm";
import ColorForm from "cms/back-office/components/contentForms/ColorForm";
import FileForm from "cms/back-office/components/contentForms/FileForm";
import IconForm from "cms/back-office/components/contentForms/IconForm";
import ImageForm from "cms/back-office/components/contentForms/ImageForm";
import ImagesForm from "cms/back-office/components/contentForms/ImagesForm";
import SliderForm from "cms/back-office/components/contentForms/SliderForm";
import InputForm from "cms/back-office/components/contentForms/InputForm";
import LinkForm from "cms/back-office/components/contentForms/LinkForm";
import SubpageForm from "cms/back-office/components/contentForms/SubpageForm";
import SelectForm from "cms/back-office/components/contentForms/SelectForm";
import TextAreaForm from "cms/back-office/components/contentForms/TextAreaForm";
import CodeBlockForm from "cms/back-office/components/contentForms/CodeBlockForm";
import TabsForm from "cms/back-office/components/contentForms/TabsForm";
import SiteSettingsForm from "cms/back-office/components/contentForms/SiteSettingsForm";
import CmsHooks, { HOOKS } from "cms/utils/CmsHooks";

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: "center"
  },
  fieldset: {
    marginBottom: "1rem",
    "& > legend": {
      color: theme.palette.secondary.text
    }
  },
  formControl: props => ({
    marginBottom: "1rem",
    width: props.width || "100%"
  }),
  resize: {
    "& > * > textarea": {
      resize: "vertical"
    }
  },
  slider: {
    margin: `${theme.spacing(2)} auto 0 auto`,
    width: "90%"
  },
  iconWrapper: {
    display: "inline-block",
    verticalAlign: "middle",
    marginRight: theme.spacing(1)
  },
  icon: {
    fontSize: "1.71rem",
    height: "1.71rem"
  },
  labelWithTooltip: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    columnGap: theme.spacing(1)
  }
}));

const ContentForm = props => {
  const { content, topLevel, onContentChange, formSubmitted, required, regexValidation } = props;

  const { id, key, type, label, children, editionModal, tooltip, width } = content;

  const classes = useStyles({ width });

  if (editionModal && !topLevel) {
    return null;
  }

  let Form = null;
  let formProps = {};

  const getFormComponentFromProject = CmsHooks.getHook(HOOKS.ContentForm_getFormComponent);
  if (typeof getFormComponentFromProject === "function") {
    Form = getFormComponentFromProject({ type });
  }
  if (!Form) {
    switch (type) {
      case contentsTypes.EDITABLE_INPUT:
      case contentsTypes.INPUT:
      case contentsTypes.URL:
      case contentsTypes.NUMERIC:
        Form = InputForm;
        break;
      case contentsTypes.COLOR:
        Form = ColorForm;
        break;
      case contentsTypes.INPUT_NUMBER:
        formProps = { type: "number" };
        Form = InputForm;
        break;
      case contentsTypes.SHORT_TEXT:
        Form = TextAreaForm;
        break;
      case contentsTypes.CHECKBOX:
        Form = CheckboxForm;
        break;
      case contentsTypes.SELECT:
        Form = SelectForm;
        break;
      case contentsTypes.IMAGE:
      case contentsTypes.IMAGE_AS_FILE:
        Form = ImageForm;
        break;
      case contentsTypes.IMAGES:
        Form = ImagesForm;
        break;
      case contentsTypes.SLIDER:
        Form = SliderForm;
        break;
      case contentsTypes.RADIO_GROUP:
        Form = RadioGroupForm;
        break;
      case contentsTypes.FILE_IMAGE:
        formProps = { image: true };
        Form = FileForm;
        break;
      case contentsTypes.FILE:
        Form = FileForm;
        break;
      case contentsTypes.LINK:
        Form = LinkForm;
        break;
      case contentsTypes.SUBPAGE:
        Form = SubpageForm;
        break;
      case contentsTypes.ICON:
        Form = IconForm;
        break;
      case contentsTypes.TABS:
        Form = TabsForm;
        break;
      case contentsTypes.SITE_SETTINGS:
        Form = SiteSettingsForm;
        break;
      case contentsTypes.CODE_BLOCK_TEXT:
        Form = CodeBlockForm;
        break;
      default:
        break;
    }
  }

  if (Form) {
    return (
      <Form
        key={id || key}
        content={content}
        onContentChange={onContentChange}
        classes={classes}
        formSubmitted={formSubmitted}
        required={required}
        regexValidation={regexValidation || content.regexValidation}
        tooltip={tooltip}
        {...formProps}
      />
    );
  }
  if (children) {
    const childrenForm = children
      .map(child => {
        const onChildChange = newChild => {
          onContentChange({
            ...content,
            children: children.map(c => (c === child ? newChild : c))
          });
        };

        return ContentForm({
          content: child,
          onContentChange: onChildChange,
          topLevel: false,
          formSubmitted,
          required: content.requiredByKey && content.requiredByKey.includes(child.key),
          regexValidation: child.regexValidation
        });
      })
      .filter(c => !!c);
    if (childrenForm.length) {
      if (topLevel) {
        return (
          <Fragment>
            <h2 className={classes.title}>{label}</h2>
            <div>{childrenForm}</div>
          </Fragment>
        );
      }
      return (
        <fieldset key={id || key} className={classes.fieldset}>
          <legend>{label}</legend>
          {childrenForm}
        </fieldset>
      );
    }
  }
  return null;
};

ContentForm.propTypes = {
  content: PropTypes.shape().isRequired,
  topLevel: PropTypes.bool,
  editionModal: PropTypes.bool,
  formSubmitted: PropTypes.bool,
  required: PropTypes.bool,
  onContentChange: PropTypes.func,
  regexValidation: PropTypes.string,
  tooltip: PropTypes.string
};

ContentForm.defaultProps = {
  topLevel: false,
  editionModal: false,
  formSubmitted: false,
  required: false,
  onContentChange: null,
  regexValidation: null,
  tooltip: null
};

export default ContentForm;
