import customTypes from "customComponents/customTypes";
import InfoBlockIcon from "cms/back-office/icons/InfoBlockIcon";
import contentsTypes from "cms/enums/contentsTypes";

export default [
  {
    label: "Bloc de présentation",
    type: customTypes.PRESENTATION_BOX,
    IconComponent: InfoBlockIcon,
    editionModal: true,
    editionModalPosition: "top",
    children: [
      {
        label: "Couleur de fond",
        key: "backgroundColor",
        type: contentsTypes.COLOR,
        value: "#F7F7F7"
      },
      {
        label: "Couleur de titre",
        key: "titleColor",
        type: contentsTypes.COLOR,
        value: "#26348B"
      },
      {
        label: "Couleur de description",
        key: "descriptionColor",
        type: contentsTypes.COLOR,
        value: "rgba(0, 0, 0, 0.87)"
      },
      {
        label: "Image",
        key: "image",
        type: contentsTypes.IMAGE,
        canChangePosition: false,
        value: ""
      },
      {
        label: "Titre",
        key: "title",
        type: contentsTypes.EDITABLE_INPUT,
        value: ""
      },
      {
        key: "dynamicPartBeforeDescription",
        type: contentsTypes.DYNAMIC,
        value: ""
      },
      {
        label: "Description",
        key: "description",
        type: contentsTypes.TEXT,
        value: ""
      },
      {
        key: "dynamicPartAfterDescription",
        type: contentsTypes.DYNAMIC,
        value: ""
      },
      {
        label: "Grande police pour la description",
        key: "descriptionLarge",
        type: contentsTypes.CHECKBOX,
        value: "false"
      },
      {
        label: "Bouton",
        key: "buttonComponent",
        type: contentsTypes.BUTTON,
        value: ""
      }
    ]
  },
  {
    label: "Panneau d'information",
    type: customTypes.INFO_BLOCK,
    icon: "columns",
    editionModal: true,
    editionModalPosition: "top",
    children: [
      {
        label: "Titre de la première partie",
        key: "firstTitle",
        type: contentsTypes.EDITABLE_INPUT,
        value: ""
      },
      {
        label: "Sous titre de la première partie",
        key: "firstSubTitle",
        type: contentsTypes.EDITABLE_INPUT,
        value: ""
      },
      {
        key: "firstDynamicPart",
        type: contentsTypes.DYNAMIC,
        value: ""
      },
      {
        label: "Couleur de titre de la première partie",
        key: "firstPartTitleColor",
        type: contentsTypes.COLOR,
        value: "#000000"
      },
      {
        label: "Couleur de texte de la première partie",
        key: "firstPartColor",
        type: contentsTypes.COLOR,
        value: "rgba(0, 0, 0, 0.8)"
      },
      {
        label: "Couleur de fond de la première partie",
        key: "firstPartBackgroundColor",
        type: contentsTypes.COLOR,
        value: "#EEEEEE"
      },
      {
        label: "Titre de la deuxième partie",
        key: "secondTitle",
        type: contentsTypes.EDITABLE_INPUT,
        value: ""
      },
      {
        label: "Sous titre de la deuxième partie",
        key: "secondSubTitle",
        type: contentsTypes.EDITABLE_INPUT,
        value: ""
      },
      {
        key: "secondDynamicPart",
        type: contentsTypes.DYNAMIC,
        value: ""
      },
      {
        label: "Couleur de texte de la deuxième partie",
        key: "secondPartColor",
        type: contentsTypes.COLOR,
        value: "#FFFFFF"
      },
      {
        label: "Couleur de fond de la deuxième partie",
        key: "secondPartBackgroundColor",
        type: contentsTypes.COLOR,
        value: "#26348B"
      }
    ]
  },
  {
    label: "Card de discours",
    type: customTypes.SPEECH_CARD,
    editionModal: true,
    editionModalPosition: "top",
    children: [
      {
        label: "Titre",
        key: "title",
        type: contentsTypes.EDITABLE_INPUT,
        value: ""
      },
      {
        label: "Discours",
        key: "speech",
        type: contentsTypes.TEXT,
        value: ""
      },
      {
        label: "Photo",
        key: "photo",
        type: contentsTypes.IMAGE,
        value: ""
      },
      {
        label: "Nom",
        key: "name",
        type: contentsTypes.EDITABLE_INPUT,
        value: ""
      },
      {
        label: "Poste",
        key: "position",
        type: contentsTypes.EDITABLE_INPUT,
        value: ""
      }
    ]
  },
  {
    label: "Card",
    type: customTypes.CUSTOM_CARD,
    editionModal: true,
    editionModalPosition: "top",
    children: [
      {
        label: "Type",
        key: "type",
        type: contentsTypes.RADIO_GROUP,
        options: [
          { label: "Hébergement médicalisé", value: "medicalSupport" },
          { label: "Résidence avec des services", value: "needAssist" },
          { label: "Accompagnement à domicile", value: "stayHome" }
        ],
        value: "medicalSupport"
      },
      {
        label: "Titre",
        key: "title",
        type: contentsTypes.EDITABLE_INPUT,
        value: ""
      }
    ]
  },
  {
    label: "Bloc avec des onglets",
    type: customTypes.CUSTOM_TABS,
    editionModal: true,
    editionModalPosition: "top",
    children: [
      {
        label: "Titre",
        key: "title",
        type: contentsTypes.EDITABLE_INPUT,
        value: ""
      },
      {
        label: "Couleur de fond",
        key: "backgroundColor",
        type: contentsTypes.COLOR,
        value: "#E9F7F7"
      },
      {
        key: "tabsContent",
        type: contentsTypes.TABS,
        children: [
          {
            key: "tabs",
            type: contentsTypes.TAB
          }
        ]
      }
    ]
  },
  {
    label: "Accès à la résidence",
    type: customTypes.PATH_OPTIONS,
    dynamicChildKey: "pathOptions",
    children: [
      {
        key: "pathOptions",
        type: customTypes.PATH_OPTION
      }
    ]
  },
  {
    type: customTypes.PATH_OPTION,
    label: "Moyen d'accès",
    editionModal: true,
    editionModalPosition: "top",
    children: [
      {
        key: "transportType",
        label: "Type de transport",
        type: contentsTypes.SELECT,
        options: [
          { label: "En bus", value: "bus" },
          { label: "En tramway", value: "tram" },
          { label: "En métro", value: "subway" },
          { label: "En voiture", value: "car" },
          { label: "En train", value: "train" }
        ],
        value: "car"
      },
      {
        key: "description",
        label: "Description",
        type: contentsTypes.TEXT,
        value: ""
      }
    ]
  },
  {
    type: contentsTypes.TAB,
    label: "Onglet",
    children: [
      {
        key: "title",
        label: "Titre",
        type: contentsTypes.INPUT,
        value: ""
      },
      {
        key: "image",
        label: "Image",
        type: contentsTypes.IMAGE,
        value: ""
      },
      {
        key: "children",
        type: contentsTypes.DYNAMIC
      }
    ]
  },
  {
    label: "Lien mailto",
    type: customTypes.MAILTO,
    editionModal: true,
    editionModalPosition: "top",
    requiredByKey: ["text", "recipient"],
    children: [
      {
        label: "Texte",
        key: "text",
        type: contentsTypes.INPUT,
        value: ""
      },
      {
        label: "Email de destination",
        key: "recipient",
        type: contentsTypes.INPUT,
        value: ""
      },
      {
        label: "Objet",
        key: "subject",
        type: contentsTypes.INPUT,
        value: ""
      },
      {
        label: "Corps",
        key: "body",
        type: contentsTypes.INPUT,
        value: ""
      }
    ]
  },
  {
    label: "Description d'un service",
    type: customTypes.SERVICE_DESCRIPTION,
    editionModal: true,
    editionModalPosition: "top",
    children: [
      {
        label: "Titre",
        key: "title",
        type: contentsTypes.SELECT,
        options: [
          { label: "Aide à l’autonomie", value: "Aide à l’autonomie" },
          { label: "Aide à l’entretien du domicile", value: "Aide à l’entretien du domicile" },
          { label: "Assistance sortie d’hospitalisation", value: "Assistance sortie d’hospitalisation" },
          { label: "Services +", value: "Services +" }
        ],
        value: "Aide à l’autonomie"
      },
      {
        label: "Sous-titre",
        key: "subtitle",
        type: contentsTypes.EDITABLE_INPUT,
        value: ""
      },
      {
        label: "Image",
        key: "image",
        type: contentsTypes.IMAGE,
        value: ""
      },
      {
        key: "dynamic",
        type: contentsTypes.DYNAMIC,
        value: ""
      }
    ]
  },
  {
    label: "Services d'une catégorie",
    type: customTypes.FEATURES,
    editionModal: true,
    editionModalPosition: "top",
    children: [
      {
        label: "Catégorie",
        key: "category",
        type: contentsTypes.SELECT,
        options: [
          { label: "Vie quotidienne", value: "everyday life" },
          { label: "Animations", value: "activities" },
          { label: "Bien être", value: "well being" },
          { label: "Activités spécifiques", value: "therapeutic activities" }
        ],
        value: "everyday life"
      },
      {
        label: "Inclus",
        key: "included",
        type: customTypes.FEATURES_LIST,
        value: ""
      },
      {
        label: "Complémentaires",
        key: "available",
        type: customTypes.FEATURES_LIST,
        value: ""
      },
      {
        label: "Bouton",
        key: "buttonComponent",
        type: contentsTypes.BUTTON,
        value: ""
      }
    ]
  },
  {
    label: "Services",
    type: customTypes.FEATURES_LIST,
    value: ""
  },
  {
    label: "Service",
    type: customTypes.FEATURE,
    children: [
      {
        label: "Nom",
        key: "name",
        type: contentsTypes.INPUT,
        value: ""
      },
      {
        label: "Description",
        key: "description",
        type: contentsTypes.INPUT,
        value: ""
      },
      {
        label: "Catégorie",
        key: "category",
        type: contentsTypes.INPUT,
        value: ""
      },
      {
        label: "Icône",
        key: "icon",
        type: contentsTypes.INPUT,
        value: ""
      }
    ]
  }
];
