import React from "react";
import NodesListing from "da-ged-web/components/NodesListing/NodesListing";
import FullToolbar from "da-ged-web/components/Toolbar/FullToolbar";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {
    height: "85vh",
    display: "flex",
    flexDirection: "column"
  }
}));

const GEDModal = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <FullToolbar />
      </div>
      <div style={{ flex: "1" }}>
        <NodesListing
          tableProps={{
            rowHeight: 35
          }}
        />
      </div>
    </div>
  );
};

export default GEDModal;
