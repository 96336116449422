import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MuiLink from "@mui/material/Link";
import AdminMenuItems from "cms/back-office/components/AdminMenuItems";
import Icon from "cms/editableComponents/Icon";

const COLUMN_KEY = "LinkInternal";

const useStyles = makeStyles(() => ({
  root: {}
}));

const AdminFooterForm = props => {
  const { footer, onFooterChange, canUpdate } = props;
  const { contents = [] } = footer;

  const sortItemsByColumn = (a, b) => {
    if (a.length < b.length) return -1;
    if (a.length > b.length) return 1;
    return a.localeCompare(b);
  };

  const getColumnsByContents = useCallback(() => {
    const columnsByContents = contents.reduce((list, content) => {
      if (!list.includes(content.key)) list.push(content.key);
      return list;
    }, []);
    if (columnsByContents.length === 0) return [COLUMN_KEY];
    return columnsByContents.sort(sortItemsByColumn);
  }, [contents]);

  const [columns, setColumns] = useState(getColumnsByContents());

  useEffect(() => {
    setColumns(getColumnsByContents());
  }, [getColumnsByContents]);

  const classes = useStyles();

  const handleItemsChange = columnKey => items => {
    onFooterChange({
      ...footer,
      contents: [...contents.filter(child => child.key !== columnKey), ...items].sort((a, b) =>
        sortItemsByColumn(a.key, b.key)
      )
    });
  };

  const handleClickAddColumn = () => {
    const lastColumn = columns[columns.length - 1];
    const keySuffix = lastColumn.replace(COLUMN_KEY, "");
    setColumns([...columns, `${COLUMN_KEY}${Number(keySuffix || "0") + 1}`]);
  };

  const handleMove = (originIndex, targetIndex) => {
    const columnOrigin = columns[originIndex];
    const columnTarget = columns[targetIndex];

    onFooterChange({
      ...footer,
      contents: contents
        .map(content => {
          const contentTmp = { ...content };
          if (content.key === columnOrigin) {
            contentTmp.key = columnTarget;
          } else if (content.key === columnTarget) {
            contentTmp.key = columnOrigin;
          }
          return contentTmp;
        })
        .sort((a, b) => sortItemsByColumn(a.key, b.key))
    });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {columns.map((columnKey, index) => (
          <Grid item key={columnKey} xs={4}>
            <Grid container justifyContent="space-between" alignItems="center">
              {canUpdate && (
                <IconButton onClick={() => handleMove(index, index - 1)} disabled={index === 0} size="large">
                  <Icon icon="chevron-left" title="Déplacer à gauche" />
                </IconButton>
              )}
              <h4>Colonne {index + 1}</h4>
              {canUpdate && (
                <IconButton
                  onClick={() => handleMove(index, index + 1)}
                  disabled={index === columns.length - 1}
                  size="large"
                >
                  <Icon icon="chevron-right" title="Déplacer à droite" />
                </IconButton>
              )}
            </Grid>
            <AdminMenuItems
              items={contents.filter(c => c.key === columnKey)}
              itemKey={columnKey}
              onItemsChange={handleItemsChange(columnKey)}
              maxDepth={0}
              canUpdate={canUpdate}
            />
          </Grid>
        ))}
        {canUpdate && columns.length < 6 && (
          <Grid item key={`${columns[0]}${columns.length}`} xs={4}>
            <h4>
              <MuiLink component="button" variant="body2" onClick={() => handleClickAddColumn()}>
                Ajouter une colonne
              </MuiLink>
            </h4>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

AdminFooterForm.propTypes = {
  footer: PropTypes.shape(),
  onFooterChange: PropTypes.func.isRequired,
  canUpdate: PropTypes.bool
};

AdminFooterForm.defaultProps = {
  footer: {},
  canUpdate: false
};

export default AdminFooterForm;
