import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles(() => ({
  loadingPage: {
    display: "flex",
    height: "100vh",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: "1.3rem",
    backgroundColor: "#fff",
    "& .MuiCircularProgress-circle": {
      stroke: "#3787FF",
      strokeLinecap: "round"
    },
    "& > span": {
      color: "#666666",
      marginTop: 16
    }
  }
}));

const LoadingPage = props => {
  const { title } = props;

  const classes = useStyles();

  return (
    <div className={classes.loadingPage}>
      <CircularProgress />
      <span>{title}</span>
    </div>
  );
};

LoadingPage.propTypes = {
  title: PropTypes.string.isRequired
};

export default LoadingPage;
