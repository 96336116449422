import React from "react";
import PropTypes from "prop-types";
import customTypes from "customComponents/customTypes";
import { getDynamicComponent } from "cms/utils/templatePropsUtils";
import DynamicAdmin from "cms/back-office/components/DynamicAdmin";

const getEditorComponent = ({ content, handleContentChange, value, id }) => {
  const { type } = content;
  switch (type) {
    case customTypes.PATH_OPTIONS: {
      return (
        <DynamicAdmin
          content={content}
          addContentText="Insérer un moyen d'accès"
          deleteContentText="Supprimer cet élément"
          contentsTypes={[customTypes.PATH_OPTION]}
          onContentChange={handleContentChange}
        >
          {getDynamicComponent(type, value, id)}
        </DynamicAdmin>
      );
    }
    default:
      return null;
  }
};

getEditorComponent.propTypes = {
  content: PropTypes.shape().isRequired,
  value: PropTypes.shape().isRequired,
  id: PropTypes.string.isRequired,
  handleContentChange: PropTypes.func.isRequired
};

export default getEditorComponent;
