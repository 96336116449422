import React from "react";
import { Box, Typography } from "@mui/material";
import ManageUsers from "cms/back-office/components/ManageUsers";

const ManageUsersPage = () => {
  return (
    <Box sx={{ height: "100%", p: 4, bgcolor: "layout.background" }}>
      <Typography variant="h1" sx={{ mb: 4 }}>
        Gestion des utilisateurs
      </Typography>
      <Box sx={{ width: "100%" }}>
        <ManageUsers />
      </Box>
    </Box>
  );
};

export default ManageUsersPage;
