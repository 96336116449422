import contentsTypes from "cms/enums/contentsTypes";
import customTypes from "customComponents/customTypes";
import PropTypes from "prop-types";
import TabsForm from "customComponents/contentForms/TabsForm";
import FeaturesForm from "customComponents/contentForms/FeaturesForm";

const getFormComponent = ({ type }) => {
  switch (type) {
    case contentsTypes.TABS:
      return TabsForm;
    case customTypes.FEATURES:
      return FeaturesForm;
    default:
      return null;
  }
};

getFormComponent.propTypes = {
  type: PropTypes.string.isRequired
};

export default getFormComponent;
