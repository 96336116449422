import React, { useState, createRef, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  sizer: props => ({
    display: props.showInput ? "none" : "inline-block",
    width: "100%",
    border: "1px dashed #999",
    minHeight: "1em",
    cursor: "text"
  }),
  input: props => ({
    display: props.showInput ? "inline-block" : "none",
    color: "inherit"
  })
}));

const AutoResizeInput = props => {
  const { value, onBlur, onChange, debounce, ...others } = props;

  const [showInput, setShowInput] = useState(false);

  const classes = useStyles({ showInput });

  const [currentTimeOut, setCurrentTimeOut] = useState(null);
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleChange = e => {
    const { value: inputValue } = e.target;
    setLocalValue(inputValue);
    if (currentTimeOut) {
      clearTimeout(currentTimeOut);
    }
    setCurrentTimeOut(
      setTimeout(() => {
        onChange(inputValue);
      }, debounce)
    );
  };

  const inputRef = createRef();

  useEffect(() => {
    if (inputRef.current && showInput) {
      inputRef.current.focus();
    }
  }, [showInput, inputRef]);

  const handleSizerClick = e => {
    e.stopPropagation();
    setShowInput(true);
  };

  const handleInputBlur = e => {
    setShowInput(false);
    if (typeof onBlur === "function") {
      onBlur(e);
    }
  };

  return (
    <Fragment>
      <span onClick={handleSizerClick} className={classes.sizer}>
        <pre>{localValue}</pre>
      </span>
      <textarea
        {...others}
        onChange={handleChange}
        className={classes.input}
        value={localValue}
        ref={inputRef}
        onBlur={handleInputBlur}
      />
    </Fragment>
  );
};

AutoResizeInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  debounce: PropTypes.number
};

AutoResizeInput.defaultProps = {
  value: "",
  onBlur: null,
  debounce: 2000
};

export default AutoResizeInput;
