import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Icon from "cms/editableComponents/Icon";

const IconLabel = props => {
  const { icon, title } = props;

  return (
    <Grid container spacing={1}>
      <Grid item xs="auto">
        <Icon icon={icon} title={title} />
      </Grid>
      <Grid item xs="auto">
        {title}
      </Grid>
    </Grid>
  );
};

IconLabel.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default IconLabel;
