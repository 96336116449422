import React from "react";
import CmsHooks, { HOOKS } from "cms/utils/CmsHooks";
import getEditorComponent from "customComponents/getEditorComponent";
import getDynamicAdminContentsTypes from "customComponents/getDynamicAdminContentsTypes";
import getFormComponent from "customComponents/getFormComponent";
import customContents from "customComponents/customContents";
import Routes from "bo/Routes";
import establishment from "templates/establishment/establishment";
import jobOffers from "templates/jobOffers/jobOffers";
import home from "templates/home/home";
import articles from "templates/articles/articles";
import residences from "templates/residences/residences";
import arpavieDom from "templates/arpavieDom/arpavieDom";

export default () => {
  CmsHooks.registerHook(HOOKS.templateAdminUtils_getEditorComponent, getEditorComponent);
  CmsHooks.registerHook(HOOKS.templateAdminUtils_getDynamicAdminContentsTypes, getDynamicAdminContentsTypes);
  CmsHooks.registerHook(HOOKS.ContentForm_getFormComponent, getFormComponent);
  CmsHooks.registerHook(HOOKS.adminContentsUtils_contents, customContents);
  CmsHooks.registerHook(HOOKS.Bo_Routes, <Routes />);
  CmsHooks.registerHook(HOOKS.TEMPLATES_excludedTemplatesForPageUpdate, [residences]);
  CmsHooks.registerHook(HOOKS.TEMPLATES_excludedTemplatesForPageDelete, [
    home,
    establishment,
    jobOffers,
    articles,
    residences,
    arpavieDom
  ]);
  CmsHooks.registerHook(HOOKS.TEMPLATES_excludedTemplatesForPageCreation, [
    home,
    establishment,
    jobOffers,
    articles,
    residences,
    arpavieDom
  ]);
};
