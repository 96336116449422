import React, { useState, createRef, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { makeStyles } from "@mui/styles";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import { SketchPicker } from "react-color";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative"
  },
  colorPicker: {
    position: "fixed",
    zIndex: "1800"
  }
}));

const ColorForm = props => {
  const {
    content,
    classes,
    onContentChange,
    formSubmitted,
    required,
    regexValidation,
    tooltip,
    onClick,
    ...others
  } = props;

  const { id, label, value } = content;

  const [displayPicker, setDisplayPicker] = useState(false);

  const componentClasses = useStyles();

  const labelRef = createRef();
  const pickerRef = createRef();
  const sketchPickerRef = useRef();

  const setPickerPosition = useCallback(() => {
    if (labelRef.current && pickerRef.current) {
      const position = labelRef.current.getBoundingClientRect();
      pickerRef.current.style.left = `${position.x}px`;
      pickerRef.current.style.bottom = `${window.innerHeight - position.y + 8}px`;
    }
  }, [labelRef, pickerRef]);

  useEffect(() => {
    setPickerPosition();
  }, [setPickerPosition]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", setPickerPosition, true);
    }
    return () => {
      window.removeEventListener("scroll", setPickerPosition);
    };
  }, [setPickerPosition]);

  const handleValidateColorPicker = color => {
    onContentChange({
      ...content,
      value: color.source === "rgb" ? `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})` : color.hex
    });
  };

  const handleClickInput = e => {
    if (typeof onClick === "function") {
      onClick(e);
    }
    setDisplayPicker(true);
  };

  const handleInputChange = e => {
    onContentChange({
      ...content,
      value: e.target.value
    });
  };

  const showRequiredError = required && formSubmitted && !value;
  const showRegexValidationError = regexValidation && formSubmitted && value && !value.match(regexValidation);

  useEffect(() => {
    const checkIfClickedOutsideSketchPicker = e => {
      if (displayPicker && sketchPickerRef.current && !sketchPickerRef.current.contains(e.target)) {
        setDisplayPicker(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutsideSketchPicker);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutsideSketchPicker);
    };
  }, [displayPicker]);

  return (
    <FormControl
      className={classnames(componentClasses.root, classes.formControl)}
      key={id}
      error={showRequiredError || showRegexValidationError}
    >
      <InputLabel
        sx={{ pointerEvents: "auto" }}
        ref={labelRef}
        shrink
        className={tooltip ? classes.labelWithTooltip : null}
      >
        {label}
        {tooltip && (
          <Tooltip title={tooltip}>
            <HelpIcon />
          </Tooltip>
        )}
      </InputLabel>
      <Input value={value} onChange={handleInputChange} onClick={handleClickInput} {...others} />
      <div className={componentClasses.colorPicker} ref={pickerRef}>
        {displayPicker && (
          <div ref={sketchPickerRef}>
            <SketchPicker width={250} color={value} onChangeComplete={handleValidateColorPicker} />
          </div>
        )}
      </div>
      {showRequiredError && <FormHelperText error>Champ obligatoire</FormHelperText>}
      {showRegexValidationError && <FormHelperText error>{label} invalide</FormHelperText>}
    </FormControl>
  );
};

ColorForm.propTypes = {
  content: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
  formSubmitted: PropTypes.bool,
  required: PropTypes.bool,
  regexValidation: PropTypes.string,
  tooltip: PropTypes.string,
  onClick: PropTypes.func
};

ColorForm.defaultProps = {
  formSubmitted: false,
  required: false,
  regexValidation: null,
  tooltip: null,
  onClick: null
};

export default ColorForm;
