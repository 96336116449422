import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
// eslint-disable-next-line import/no-extraneous-dependencies
import "simplebar/dist/simplebar.min.css";

function EnhancedTableHead(props) {
  const { classes, order, orderBy, columns, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order && order.toLowerCase() : false}
          >
            <TableSortLabel
              hideSortIcon={!headCell.sortable}
              active={orderBy === headCell.id}
              direction={order && order.toLowerCase()}
              onClick={headCell.sortable && createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "DESC" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.shape().isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["ASC", "DESC"]).isRequired,
  orderBy: PropTypes.string.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    borderColor: theme.palette.layout.border
  },
  tableWrapper: {
    flex: "1 0 auto",
    position: "relative"
  },
  tableContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

const EnhancedTable = props => {
  const {
    columns,
    data,
    order,
    orderBy,
    page,
    totalRows,
    rowsPerPage,
    rowsPerPageOptions,
    onRequestSort,
    onChangePage,
    onChangeRowsPerPage,
    onRowClick,
    renderRowDetail
  } = props;

  const classes = useStyles();

  const [openedRows, setOpenedRows] = useState([]);

  const isRowOpen = row => openedRows.includes(row.id);

  const openRow = (row = {}) => {
    const { id } = row;
    if (id) {
      setOpenedRows([...openedRows, id]);
    }
  };

  const closeRow = (row = {}) => {
    const { id } = row;
    if (id) {
      setOpenedRows(openedRows.filter(r => r !== id));
    }
  };

  const toggleRow = row => {
    if (isRowOpen(row)) {
      closeRow(row);
    } else {
      openRow(row);
    }
  };

  let handleRowClick = onRowClick;
  if (!handleRowClick && renderRowDetail) {
    handleRowClick = (event, row) => {
      toggleRow(row);
    };
  }

  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <TableContainer className={classes.tableContainer}>
          <Table aria-labelledby="tableTitle" aria-label="enhanced table" stickyHeader>
            <EnhancedTableHead
              classes={classes}
              columns={columns}
              order={order}
              orderBy={orderBy}
              onRequestSort={onRequestSort}
            />
            <TableBody>
              {data.map(row => {
                return (
                  <Fragment key={row.id}>
                    <TableRow
                      hover
                      onClick={event => typeof handleRowClick === "function" && handleRowClick(event, row)}
                      style={{ cursor: typeof handleRowClick === "function" ? "pointer" : "default" }}
                      tabIndex={-1}
                    >
                      {columns.map(column => (
                        <TableCell key={column.id} style={column.style && column.style(row)} {...column.cellProps}>
                          {column.render(row)}
                        </TableCell>
                      ))}
                    </TableRow>
                    {isRowOpen(row) && (
                      <TableRow>
                        <TableCell colSpan={columns.length}>{renderRowDetail(row, () => closeRow(row))}</TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        labelRowsPerPage=""
        backIconButtonProps={{
          "aria-label": "Pages suivantes"
        }}
        nextIconButtonProps={{
          "aria-label": "Pages précédentes"
        }}
        onPageChange={(event, p) => onChangePage(p + 1)}
        onRowsPerPageChange={onChangeRowsPerPage}
      />
    </div>
  );
};

EnhancedTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      render: PropTypes.func.isRequired,
      style: PropTypes.func,
      cellProps: PropTypes.shape()
    })
  ),
  data: PropTypes.arrayOf(PropTypes.any),
  order: PropTypes.oneOf(["ASC", "DESC"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  page: PropTypes.number,
  totalRows: PropTypes.number,
  rowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  onRequestSort: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  onRowClick: PropTypes.func,
  renderRowDetail: PropTypes.func
};

EnhancedTable.defaultProps = {
  columns: [],
  data: [],
  page: 1,
  totalRows: 0,
  rowsPerPage: 10,
  rowsPerPageOptions: [5, 10, 25],
  onRowClick: null,
  renderRowDetail: null
};

export default EnhancedTable;
