var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { useMutation, useQuery } from "react-query";
import { GenericService } from "services/generic.service";
export var entityName = "areas";
var AreaService = /** @class */ (function (_super) {
    __extends(AreaService, _super);
    function AreaService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return AreaService;
}(GenericService));
var service = new AreaService(entityName);
export var useAreaList = function (params, options) {
    if (params === void 0) { params = {}; }
    if (options === void 0) { options = {}; }
    return useQuery([entityName], function () { return service.findMany(params); }, options);
};
export var useAreaCreate = function (options) {
    if (options === void 0) { options = {}; }
    return useMutation(function (payload) { return service.create(payload); }, options);
};
export var useAreaUpdate = function (config) {
    if (config === void 0) { config = {}; }
    return useMutation(function (area) { return service.update(area); }, config);
};
export var useAreaDelete = function (config) {
    if (config === void 0) { config = {}; }
    return useMutation(function (area) { return service.delete(area.id); }, config);
};
