import contentsTypes from "cms/enums/contentsTypes";
import customTypes from "customComponents/customTypes";

// eslint-disable-next-line react/prop-types
const getDynamicAdminContentsTypes = ({ type }) => {
  switch (type) {
    case contentsTypes.DYNAMIC:
      return [customTypes.PRESENTATION_BOX, customTypes.INFO_BLOCK];
    default:
      return [];
  }
};

export default getDynamicAdminContentsTypes;
