import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useQueryClient } from "react-query";
import AdminContext from "cms/back-office/components/AdminContext";
import EstablishmentForm from "bo/EstablishmentForm";
import { useEstablishmentCreate, entityName, createInitalPageVersion } from "services/establishments.service";
var CreateEstablishment = function (props) {
    var onValidate = props.onValidate, onCancel = props.onCancel;
    var _a = React.useContext(AdminContext), displaySuccess = _a.displaySuccess, displayError = _a.displayError;
    var queryClient = useQueryClient();
    var createEstablishment = useEstablishmentCreate({
        onSuccess: function (establishment) {
            queryClient.invalidateQueries(entityName);
            createInitalPageVersion(establishment).then(function () {
                queryClient.invalidateQueries("page-versions");
                // @ts-ignore
                displaySuccess("Nouvel établissement créé");
                onValidate();
            });
        },
        onError: function () {
            // @ts-ignore
            displayError("Erreur lors de la création de l'établissement");
        }
    }).mutate;
    var handleValidateEstablishment = function (values) {
        createEstablishment(values);
    };
    return _jsx(EstablishmentForm, { onSubmit: handleValidateEstablishment, onCancel: onCancel }, void 0);
};
export default CreateEstablishment;
