import React from "react";
import Grid from "@mui/material/Grid";
import Button from "cms/editableComponents/Button";

const ButtonPreview = () => {
  return (
    <Grid container spacing={4}>
      <Grid item>
        <Button primary>Couleur de base</Button>
      </Grid>
      <Grid item>
        <Button>Couleur secondaire</Button>
      </Grid>
      <Grid item>
        <Button primary icon="arrow-right">
          Avec icône
        </Button>
      </Grid>
    </Grid>
  );
};

export default ButtonPreview;
