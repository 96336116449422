import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { ModalActions } from "cms/back-office/components/Modal";
import { useTheme } from "@mui/styles";

const ModalConfirm = props => {
  const {
    title,
    text,
    confirmText,
    cancelText,
    detailContent,
    onConfirm,
    onCancel,
    confirmButtonProps,
    cancelButtonProps
  } = props;

  const theme = useTheme();

  const [seeDetail, setSeeDetail] = useState(false);

  return (
    <div>
      <h2>{title}</h2>
      {text && <Box mb={2}>{text}</Box>}
      {detailContent && (
        <>
          <Box
            sx={{
              color: theme.palette.primary.main,
              cursor: "pointer",
              textDecoration: "underline",
              my: 2
            }}
            onClick={() => setSeeDetail(!seeDetail)}
          >
            {seeDetail ? "Masquer" : "Voir"} le détail
          </Box>
          {seeDetail && detailContent}
        </>
      )}
      <ModalActions>
        <Button onClick={onCancel} {...cancelButtonProps}>
          {cancelText}
        </Button>
        <Button primary onClick={onConfirm} {...confirmButtonProps}>
          {confirmText}
        </Button>
      </ModalActions>
    </div>
  );
};

ModalConfirm.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  detailContent: PropTypes.shape(),
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  confirmButtonProps: PropTypes.shape({}),
  cancelButtonProps: PropTypes.shape({})
};

ModalConfirm.defaultProps = {
  text: "",
  confirmText: "Confirmer",
  cancelText: "Annuler",
  detailContent: null,
  confirmButtonProps: {},
  cancelButtonProps: {}
};

export default ModalConfirm;
