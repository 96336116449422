import React from "react";
import { Box, Typography } from "@mui/material";
import ManageEstablishments from "./ManageEstablishments";

const ManageEstablishmentsPage = () => {
  return (
    <Box sx={{ height: "100%", p: 4, bgcolor: "layout.background", overflow: "auto", boxSizing: "border-box" }}>
      <Typography variant="h1" sx={{ mb: 4 }}>
        Gestion des établissements
      </Typography>
      <Box sx={{ width: "100%" }}>
        <ManageEstablishments />
      </Box>
    </Box>
  );
};

export default ManageEstablishmentsPage;
