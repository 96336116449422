import React, { useContext } from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { getAllDefaultContents } from "cms/back-office/utils/adminContentsUtils";
import Icon from "cms/editableComponents/Icon";
import { ThemeContext } from "cms/back-office/theme/ThemeBackOfficeProvider";
import { getCategoryLabel } from "cms/enums/categoriesContents";

const useStyles = makeStyles({
  title: {
    display: "flex",
    alignItems: "center"
  }
});

const AvailableContentsPreview = () => {
  const classes = useStyles();
  const { themeBackOffice: theme } = useContext(ThemeContext);

  const groupedContents = {};

  getAllDefaultContents()
    .filter(c => c.Preview)
    .sort((a, b) => (a.label < b.label ? -1 : 1))
    .forEach(content => {
      const { category } = content;
      groupedContents[category] = groupedContents[category] || [];
      groupedContents[category].push(content);
    });

  return Object.keys(groupedContents).map(category => (
    <React.Fragment key={category}>
      <h2 style={{ textAlign: "center" }}>{getCategoryLabel(category)}</h2>
      {groupedContents[category].map(content => {
        const { label, Preview, icon, IconComponent, type } = content;

        let contentIcon = null;
        if (icon) {
          contentIcon = <Icon icon={icon} />;
        } else if (IconComponent) {
          contentIcon = (
            <IconComponent
              color={theme.palette.layout.contentBtnBackground}
              backgroundColor={theme.palette.layout.contentBtnColor}
            />
          );
        }

        return (
          <Box mb={8} key={type}>
            <h2 className={classes.title}>
              {contentIcon}
              {contentIcon && <span>&nbsp;&nbsp;</span>}
              {label}
            </h2>
            <Preview />
          </Box>
        );
      })}
    </React.Fragment>
  ));
};

export default AvailableContentsPreview;
