import React, { Fragment } from "react";
import MoveUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MoveDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AdminActions, { AdminActionsContext } from "cms/back-office/components/AdminActions";
import AdminTemplateSettingsActions from "cms/back-office/components/AdminTemplateSettingsActions";
import DynamicAdmin from "cms/back-office/components/DynamicAdmin";
import AutoResizeInput from "cms/back-office/components/AutoResizeInput";
import AdminContentsGroups from "cms/back-office/components/AdminContentsGroups";
import CKEditorCustom from "cms/back-office/components/CKEditorCustom";
import EditContentAction from "cms/back-office/components/actions/EditContentAction";
import { enhanceContent } from "cms/back-office/utils/adminContentsUtils";
import { getDynamicComponent } from "cms/utils/templatePropsUtils";
import { fullToolbarEditor, descriptionToolbarEditor } from "cms/utils/commonUtils";
import contentsTypes from "cms/enums/contentsTypes";
import CmsHooks, { HOOKS } from "cms/utils/CmsHooks";
import { pageImageFiltersKey } from "cms/utils/imageFiltersUtil";
import { swapArrayItems } from "cms/utils/ArrayUtils";
import Text from "cms/editableComponents/Text";

const getEditorComponent = ({
  content,
  originalContent,
  sameLevelContents,
  value,
  handleContentChange,
  handleContentsChange
}) => {
  const getEditorComponentFromProject = CmsHooks.getHook(HOOKS.templateAdminUtils_getEditorComponent);
  const getDynamicAdminContentsTypes =
    CmsHooks.getHook(HOOKS.templateAdminUtils_getDynamicAdminContentsTypes) || (() => []);

  const { type, id, editionModal, isTemplateSettings, key: contentKey, label, dynamicChildKey } = content;
  // what append when content.id is null ? we are using it into actions and editorComponent as key.
  // probably generate the error:
  // Warning: Each child in a list should have a unique "key" prop.
  // Check the render method of `AdminActions`. It was passed a child from DynamicAdmin

  const handleValueChange = newValue => {
    handleContentChange({
      ...content,
      value: newValue
    });
  };

  const handleRemoveContent = () => {
    handleContentChange();
  };

  const actions = [];

  if (editionModal) {
    actions.push({
      key: `EditContent-'${id}`,
      node: (
        <EditContentAction
          key={`EditContentAction-${id}`}
          content={content}
          onContentChange={handleContentChange}
          title={`Modifier cet élément (${label}) `}
        />
      ),
      order: 1
    });
  }

  const filteredContents = sameLevelContents.filter(c => c.key === contentKey);

  const index = filteredContents.filter(c => c.key === contentKey).indexOf(originalContent);

  const previousContent = index > 0 && filteredContents[index - 1];
  const nextContent = index + 1 < filteredContents.length && filteredContents[index + 1];

  const swapContents = (contentA, contentB) => () => {
    handleContentsChange(
      swapArrayItems(sameLevelContents, sameLevelContents.indexOf(contentA), sameLevelContents.indexOf(contentB))
    );
  };

  if (previousContent) {
    actions.push({
      key: `MoveUp-${id}`,
      node: (
        <MoveUpIcon
          key={`MoveUpIcon-${id}`}
          viewBox="4 4 16 16"
          onClick={swapContents(originalContent, previousContent)}
          title="Monter"
        />
      ),
      order: 2
    });
  }

  if (nextContent) {
    actions.push({
      key: `MoveDown-${id}`,
      node: (
        <MoveDownIcon
          key={`MoveDownIcon-${id}`}
          viewBox="4 4 16 16"
          onClick={swapContents(originalContent, nextContent)}
          title="Descendre"
        />
      ),
      order: 3
    });
  }

  if (type) {
    let editorComponent = null;
    if (typeof getEditorComponentFromProject === "function") {
      editorComponent = getEditorComponentFromProject({
        content,
        value,
        index,
        handleValueChange,
        handleContentChange,
        handleRemoveContent
      });
    }
    if (!editorComponent) {
      switch (type) {
        case contentsTypes.EDITABLE_INPUT:
          editorComponent = <AutoResizeInput key={id} value={value} onChange={v => handleValueChange(v)} />;
          break;
        case contentsTypes.TEXT:
          editorComponent = getDynamicComponent(
            type,
            <CKEditorCustom
              key={id}
              onChange={data => handleValueChange(encodeURIComponent(data))}
              initData={decodeURIComponent(value)}
              type="inline"
              config={{ toolbar: fullToolbarEditor }}
            />,
            id
          );
          break;
        case contentsTypes.DYNAMIC: {
          editorComponent = (
            <DynamicAdmin
              content={content}
              addContentText="Insérer un élément ici"
              deleteContentText="Supprimer cet élément"
              contentsTypes={[
                contentsTypes.CONTAINER,
                contentsTypes.MARGIN,
                contentsTypes.TEXT,
                contentsTypes.EXPANSION_TEXT,
                contentsTypes.BUTTON,
                contentsTypes.ATTACHMENT,
                contentsTypes.IMAGES_GALLERY,
                contentsTypes.TIMELINE,
                contentsTypes.ACCORDION,
                contentsTypes.EXPANSION_PANEL,
                contentsTypes.VIDEO,
                contentsTypes.GRID,
                contentsTypes.INFO_BLOCK,
                contentsTypes.IMAGE_BLOCK,
                contentsTypes.CODE_BLOCK,
                ...getDynamicAdminContentsTypes({ type: contentsTypes.DYNAMIC })
              ]}
              onContentChange={handleContentChange}
            >
              {getDynamicComponent(type, value, id)}
            </DynamicAdmin>
          );
          break;
        }
        case contentsTypes.TIMELINE: {
          editorComponent = getDynamicComponent(
            type,
            {
              steps: (
                <DynamicAdmin
                  content={content}
                  addContentText="Ajouter une étape"
                  deleteContentText="Supprimer cette étape"
                  contentsTypes={[
                    contentsTypes.STEP_V2,
                    ...getDynamicAdminContentsTypes({ type: contentsTypes.TIMELINE })
                  ]}
                  onContentChange={handleContentChange}
                >
                  {value && value.steps}
                </DynamicAdmin>
              )
            },
            id
          );
          break;
        }
        case contentsTypes.STEP_V2_CONTENT: {
          editorComponent = (
            <DynamicAdmin
              content={content}
              addContentText="Ajouter un élément"
              deleteContentText="Supprimer cet élément"
              contentsTypes={[
                contentsTypes.MARGIN,
                contentsTypes.TEXT,
                contentsTypes.EXPANSION_TEXT,
                contentsTypes.BUTTON,
                contentsTypes.EXPANSION_PANEL,
                contentsTypes.IMAGE_BLOCK,
                ...getDynamicAdminContentsTypes({ type: contentsTypes.STEP_V2_CONTENT })
              ]}
              onContentChange={handleContentChange}
            >
              {value && value.detailsElements}
            </DynamicAdmin>
          );
          break;
        }
        case contentsTypes.ACCORDION: {
          editorComponent = (
            <DynamicAdmin
              content={content}
              addContentText="Insérer un panneau"
              deleteContentText="Supprimer ce panneau"
              contentsTypes={[
                contentsTypes.ACCORDION_PANEL,
                ...getDynamicAdminContentsTypes({ type: contentsTypes.ACCORDION })
              ]}
              onContentChange={handleContentChange}
            >
              {value && value.panels}
            </DynamicAdmin>
          );
          break;
        }
        case contentsTypes.ACCORDION_PANEL:
          editorComponent = getDynamicComponent(contentsTypes.EXPANSION_PANEL, value, id);
          break;
        case contentsTypes.EXPANSION_PANEL_DETAILS: {
          editorComponent = (
            <DynamicAdmin
              content={content}
              addContentText="Insérer un élément"
              deleteContentText="Supprimer cet élément"
              contentsTypes={[
                contentsTypes.MARGIN,
                contentsTypes.TEXT,
                contentsTypes.EXPANSION_TEXT,
                contentsTypes.BUTTON,
                contentsTypes.ATTACHMENT,
                contentsTypes.TIMELINE,
                contentsTypes.GRID,
                contentsTypes.INFO_BLOCK,
                contentsTypes.IMAGE_BLOCK,
                ...getDynamicAdminContentsTypes({ type: contentsTypes.EXPANSION_PANEL_DETAILS })
              ]}
              onContentChange={handleContentChange}
            >
              {value && value.detailsElements}
            </DynamicAdmin>
          );
          break;
        }
        case contentsTypes.GRID: {
          const { size } = value;
          let { items = [] } = value;
          if (!Array.isArray(items)) {
            items = [items];
          }

          editorComponent = (
            <DynamicAdmin
              content={content}
              addContentText="Insérer un élément"
              deleteContentText="Supprimer cet élément"
              contentsTypes={[
                contentsTypes.MARGIN,
                contentsTypes.TEXT,
                contentsTypes.EXPANSION_TEXT,
                contentsTypes.BUTTON,
                contentsTypes.ATTACHMENT,
                contentsTypes.TIMELINE,
                contentsTypes.VIDEO,
                contentsTypes.GRID,
                contentsTypes.INFO_BLOCK,
                contentsTypes.IMAGE_BLOCK,
                contentsTypes.IMAGES_GALLERY,
                contentsTypes.CODE_BLOCK,
                ...getDynamicAdminContentsTypes({ type: contentsTypes.GRID })
              ]}
              onContentChange={handleContentChange}
              maxIndexForSpacing={12 / Number(size) - 1}
              limit={content.limit}
            >
              {appendButtonToNode =>
                items.length > 0 && getDynamicComponent(type, { ...value, items: items.map(appendButtonToNode) }, id)
              }
            </DynamicAdmin>
          );
          break;
        }
        case contentsTypes.CONTAINER: {
          const { containerElement } = value;

          editorComponent = (
            <DynamicAdmin
              content={content}
              addContentText="Insérer un élément ici"
              deleteContentText="Supprimer un élément"
              contentsTypes={[
                contentsTypes.EXPANSION_TEXT,
                contentsTypes.GRID,
                contentsTypes.BUTTON,
                contentsTypes.ATTACHMENT,
                contentsTypes.TIMELINE,
                contentsTypes.ACCORDION,
                contentsTypes.EXPANSION_PANEL,
                contentsTypes.VIDEO,
                contentsTypes.INFO_BLOCK,
                contentsTypes.TEXT,
                ...getDynamicAdminContentsTypes({ type: contentsTypes.CONTAINER })
              ]}
              onContentChange={handleContentChange}
            >
              {() => containerElement && getDynamicComponent(type, value, id)}
            </DynamicAdmin>
          );
          break;
        }
        case contentsTypes.SUBPAGES_LIST: {
          const { limit } = value;
          let { pages = [] } = value;
          if (!Array.isArray(pages)) {
            pages = [pages];
          }
          editorComponent = (
            <DynamicAdmin
              content={content}
              addContentText="Insérer une page"
              deleteContentText="Supprimer cette page"
              contentsTypes={[
                contentsTypes.SUBPAGE,
                ...getDynamicAdminContentsTypes({ type: contentsTypes.SUBPAGES_LIST })
              ]}
              onContentChange={handleContentChange}
              limit={limit || content.limit}
            >
              {appendButtonToNode =>
                pages.length > 0 && getDynamicComponent(type, { pages: pages.map(appendButtonToNode) }, id, index)
              }
            </DynamicAdmin>
          );
          break;
        }
        case contentsTypes.GLOSSARY: {
          editorComponent = (
            <Fragment>
              <DynamicAdmin
                content={content}
                addContentText="Ajouter un mot / acronyme"
                contentsTypes={[
                  contentsTypes.GLOSSARY_ITEM,
                  ...getDynamicAdminContentsTypes({ type: contentsTypes.GLOSSARY })
                ]}
                onContentChange={handleContentChange}
                limit={1}
              />
              {getDynamicComponent(type, value, id, index)}
            </Fragment>
          );
          break;
        }
        case contentsTypes.GLOSSARY_ITEM: {
          editorComponent = {
            ...value,
            Wrapper: p => {
              const { children } = p;
              return (
                <AdminActions key={id} content={content}>
                  <DynamicAdmin onRemoveContent={handleRemoveContent} limit={0}>
                    {children}
                  </DynamicAdmin>
                </AdminActions>
              );
            }
          };
          break;
        }
        case contentsTypes.INFO_BLOCK_LIST: {
          const { limit } = value;
          let { [dynamicChildKey]: infos = [] } = value;
          if (!Array.isArray(infos)) {
            infos = [infos];
          }
          editorComponent = (
            <DynamicAdmin
              content={content}
              addContentText="Insérer un bloc d'information"
              deleteContentText="Supprimer"
              contentsTypes={[
                contentsTypes.INFO_BLOCK,
                ...getDynamicAdminContentsTypes({ type: contentsTypes.INFO_BLOCK_LIST })
              ]}
              onContentChange={handleContentChange}
              limit={limit || content.limit}
            >
              {appendButtonToNode =>
                infos.length > 0 && getDynamicComponent(type, { infos: infos.map(appendButtonToNode) }, id)
              }
            </DynamicAdmin>
          );
          break;
        }
        case contentsTypes.TAB_CARDS:
          break;
        case contentsTypes.TABS: {
          editorComponent = {
            ...value,
            Wrapper: p => {
              const { children } = p;
              return (
                <AdminActions key={id} content={content}>
                  {children}
                </AdminActions>
              );
            }
          };
          break;
        }
        case contentsTypes.CONTENTS_GROUPS_LIST: {
          editorComponent = <AdminContentsGroups key={id} content={content} onContentChange={handleContentChange} />;
          break;
        }
        case contentsTypes.EXPANSION_TEXT: {
          editorComponent = getDynamicComponent(type, { ...value, defaultExpanded: true }, id, index);
          break;
        }
        default:
          editorComponent = getDynamicComponent(type, value, id, index);
      }
    }
    if (isTemplateSettings) {
      return <AdminTemplateSettingsActions>{editorComponent}</AdminTemplateSettingsActions>;
    }

    if (React.isValidElement(editorComponent)) {
      return (
        <AdminActionsContext.Consumer key={id}>
          {({ parentActions }) => (
            <AdminActionsContext.Provider value={{ actions: parentActions ? parentActions.concat(actions) : actions }}>
              <AdminActions content={content}>{editorComponent}</AdminActions>
            </AdminActionsContext.Provider>
          )}
        </AdminActionsContext.Consumer>
      );
    }
    return editorComponent;
  }
  return value;
};

export const generateTemplatePropsFromContents = (contents, handleContentsChange) => {
  const props = {};
  if (Array.isArray(contents)) {
    contents.forEach(content => {
      let { value } = content;
      const { key, id } = content;
      const enchancedContent = enhanceContent(content);
      const { children } = enchancedContent;

      if (children && children.length) {
        const handleChildrenChange = newChildren => {
          handleContentsChange(
            contents.map(oldContent => (oldContent === content ? { ...content, children: newChildren } : oldContent))
          );
        };

        value = generateTemplatePropsFromContents(children, handleChildrenChange);
        value.id = id;
      }

      const handleContentChange = updatedContent => {
        handleContentsChange(
          contents.map(oldContent => (oldContent === content ? updatedContent : oldContent)).filter(c => !!c)
        );
      };

      value = getEditorComponent({
        content: enchancedContent,
        originalContent: content,
        sameLevelContents: contents,
        value,
        handleContentChange,
        handleContentsChange
      });

      // if key already exists, then it will be an array
      if (typeof props[key] !== "undefined") {
        if (!Array.isArray(props[key])) {
          props[key] = [props[key]];
        }
        props[key].push(value);
      } else {
        props[key] = value;
      }
    });
  }
  return props;
};

const generateEditablePage = (page, handlePageChange, currentTemplate) => {
  const { title, description, shortDescription, contents } = page;

  const handleTitleChange = t => {
    handlePageChange({
      ...page,
      title: t
    });
  };
  const handleDescriptionChange = data => {
    handlePageChange({
      ...page,
      description: encodeURIComponent(data)
    });
  };
  const handleShortDescriptionChange = data => {
    handlePageChange({
      ...page,
      shortDescription: encodeURIComponent(data)
    });
  };
  const handleContentsChange = c => {
    handlePageChange({
      ...page,
      contents: c
    });
  };

  const editableTitle = <AutoResizeInput value={title} onChange={v => handleTitleChange(v)} />;

  const editableDescription = (
    <Text>
      <CKEditorCustom
        key={page.id}
        initData={decodeURIComponent(description || "")}
        onChange={handleDescriptionChange}
        type="inline"
        config={{ toolbar: descriptionToolbarEditor }}
      />
    </Text>
  );

  const editableShortDescription = (
    <Text>
      <CKEditorCustom
        key={page.id}
        initData={decodeURIComponent(shortDescription || "")}
        onChange={handleShortDescriptionChange}
        type="inline"
        config={{ toolbar: descriptionToolbarEditor }}
      />
    </Text>
  );

  const displayedImage = page.image || { url: "/default-image.jpg" };

  const overrideContentPropsByInitialContentProps = (defaultContents, initialContents = []) => {
    const getInitialContent = content =>
      initialContents.find(i => i.key === content.key && i.type === content.type) || {};
    const hasChildren = content => content.children && content.children.length > 0;
    const overrideChildren = (content, initialContent) =>
      hasChildren(initialContent) && hasChildren(content)
        ? overrideContentPropsByInitialContentProps(content.children, initialContent.children)
        : content.children;
    const contentsOverrided = [];

    defaultContents.forEach(content => {
      const initialContent = getInitialContent(content);
      const contentWithChildrenOverrided = { ...content, children: overrideChildren(content, initialContent) };
      contentsOverrided.push({ ...initialContent, ...contentWithChildrenOverrided });
    });

    return contentsOverrided;
  };

  const initialContents = currentTemplate ? currentTemplate.initialContents || [] : [];

  const generatedProps = generateTemplatePropsFromContents(
    overrideContentPropsByInitialContentProps(contents, initialContents),
    handleContentsChange
  );

  const imageFiltersChild = page?.contents?.find(child => child.key === pageImageFiltersKey);

  return {
    ...page,
    title: editableTitle,
    description: editableDescription,
    shortDescription: editableShortDescription,
    image: getDynamicComponent(contentsTypes.IMAGE, {
      file: { url: displayedImage.url },
      alt: displayedImage.alt,
      title: displayedImage.title,
      filters: imageFiltersChild ? generateTemplatePropsFromContents(imageFiltersChild.children) : undefined
    }),
    contents: generatedProps
  };
};

export default generateEditablePage;
