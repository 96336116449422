import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  itemAction: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "60px",
    height: "100%",
    borderRight: `1px solid ${theme.palette.layout.border}`,
    padding: theme.spacing(0, 2),
    color: theme.palette.secondary.text,
    fontWeight: "bold",
    cursor: "pointer",
    "& > i": {
      fontSize: "1.5rem"
    },
    "&:hover": {
      backgroundColor: theme.palette.secondary.main
    },
    "@media screen and (max-width: 1400px)": {
      "& > span": {
        display: "none"
      },
      "& > i": {
        marginRight: "0 !important"
      }
    }
  },
  selected: {
    backgroundColor: theme.palette.secondary.main,
    "& > i": {
      color: "inherit"
    }
  }
}));

const AdminVersionsItemAction = props => {
  const { className, selected, ...others } = props;
  const classes = useStyles();

  return (
    <Grid item className={classnames(classes.itemAction, selected ? classes.selected : "", className)} {...others} />
  );
};

AdminVersionsItemAction.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.bool
};

AdminVersionsItemAction.defaultProps = {
  className: "",
  selected: false
};

export default AdminVersionsItemAction;
