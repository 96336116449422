import React, { useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Icon from "@mui/material/Icon";
import AdminTemplateSettingsContext from "cms/back-office/components/AdminTemplateSettingsContext";

const useStyles = makeStyles(() => ({
  wrapper: {
    position: "relative",
    border: "double #2646D4"
  },
  actions: {
    position: "absolute",
    top: "12px",
    left: "12px",
    zIndex: "50",
    background: "#ffffff66",
    display: "flex",
    borderRadius: "50%",
    padding: "2px",
    cursor: "pointer"
  }
}));

const AdminTemplateSettingsActions = props => {
  const { children, ...others } = props;

  const classes = useStyles();

  const { setDisplayAdminTemplateSettingsModal } = useContext(AdminTemplateSettingsContext);

  return (
    <div className={classes.wrapper} {...others}>
      <div className={classes.actions}>
        <Icon onClick={() => setDisplayAdminTemplateSettingsModal(true)} className="fas fa-cog" />
      </div>
      {children}
    </div>
  );
};

AdminTemplateSettingsActions.propTypes = {
  children: PropTypes.node.isRequired
};

AdminTemplateSettingsActions.defaultProps = {};

export default AdminTemplateSettingsActions;
