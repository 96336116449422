import React from "react";
import Attachment from "cms/editableComponents/Attachment";

const AttachmentPreview = () => {
  return (
    <Attachment
      title="Nom de la pièce jointe"
      description="Description de la pièce jointe"
      linkText="Lien de téléchargement"
    />
  );
};

export default AttachmentPreview;
