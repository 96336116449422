import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import contentsTypes from "cms/enums/contentsTypes";
import AdminListForm from "cms/back-office/components/contentForms/AdminListForm";
import ImageForm from "cms/back-office/components/contentForms/ImageForm";

const ImagesForm = props => {
  const { content, onContentChange, classes, formSubmitted } = props;

  const { id, key, label, children } = content;

  const fileImageType = contentsTypes.IMAGE_AS_FILE;
  const objectsChildren = children.filter(c => c.type === fileImageType) || [];

  const handleChildChange = (oldChild, newChild) => {
    onContentChange({
      ...content,
      children: children.map(c => (c === oldChild ? newChild : c))
    });
  };

  const handleChildrenChange = (oldChildren, newChildren) => {
    onContentChange({
      ...content,
      children: newChildren
    });
  };

  return (
    <Box key={id} sx={{ mb: 2 }}>
      <h2 className={classes.title}>{label}</h2>

      <AdminListForm
        inline
        contents={objectsChildren}
        elementsType={contentsTypes.IMAGE_AS_FILE}
        elementsKey="image"
        onContentsChange={newContents => handleChildrenChange(objectsChildren, newContents)}
      >
        {objectsChildren.map((currentObject, i) => (
          <ImageForm
            key={currentObject.id || key + i}
            content={currentObject}
            onContentChange={newContent => handleChildChange(currentObject, newContent)}
            classes={classes}
            formSubmitted={formSubmitted}
          />
        ))}
      </AdminListForm>
    </Box>
  );
};

ImagesForm.propTypes = {
  content: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
  formSubmitted: PropTypes.bool.isRequired
};

export default ImagesForm;
