import React from "react";
import wrappable from "cms/utils/wrappable";
import MuiTextField from "@mui/material/TextField";

const TextField = wrappable(props => {
  return (
    <MuiTextField
      InputLabelProps={{
        shrink: true
      }}
      {...props}
    />
  );
});

export default TextField;
