import React from "react";
import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/system/Box";

const CheckboxForm = props => {
  const { classes, content, onContentChange } = props;

  const { value } = content;

  const handleCheckboxChange = e => {
    onContentChange({
      ...content,
      value: e.target.checked ? "true" : "false"
    });
  };

  return (
    <Box className={classes && classes.formControl} sx={{ display: "inline-flex" }}>
      <FormControlLabel
        control={<Checkbox checked={value === "true"} onChange={handleCheckboxChange} disableRipple />}
        label={content.label}
      />
    </Box>
  );
};

CheckboxForm.propTypes = {
  content: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired
};

export default CheckboxForm;
