/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Snackbar from "cms/components/Snackbar";

const AdminContext = React.createContext({
  displaySuccess: message => null,
  displayError: message => null
});

export default AdminContext;

export const AdminContextProvider = withRouter(props => {
  const { children } = props;

  const [snackbar, setSnackbar] = useState({
    open: false
  });

  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false
    });
  };

  const displayError = message => {
    setSnackbar({
      open: true,
      duration: null,
      severity: "error",
      message
    });
  };

  const displaySuccess = message => {
    setSnackbar({
      open: true,
      duration: 5000,
      message
    });
  };

  return (
    <AdminContext.Provider
      value={{
        displaySuccess,
        displayError
      }}
    >
      <Snackbar severity="success" {...snackbar} onClose={handleCloseSnackbar} />
      {/* <ThemeBackOfficeProvider>
        <Modal open={!!adminModal.content} size={adminModal.size} title={adminModal.title} onClose={closeAdminModal}>
          {adminModal.content}
        </Modal>
      </ThemeBackOfficeProvider> */}
      {children}
    </AdminContext.Provider>
  );
});
