var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useMemo, useCallback } from "react";
import { orderBy } from "lodash";
import { withRouter } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { makeStyles } from "@mui/styles";
import { StyledEngineProvider } from "@mui/material/styles";
import { Box, Button, Grid, Input, InputAdornment, Typography, Table, TableBody, TableCell, TableSortLabel, TableRow, TableHead, TablePagination, IconButton } from "@mui/material";
import Search from "@mui/icons-material/Search";
import Icon from "cms/editableComponents/Icon";
import Modal from "cms/back-office/components/Modal";
import Loader from "cms/back-office/components/Loader";
import { queryParamPageKey, queryParamSiteKey } from "cms/back-office/components/AdminWebsiteContext";
import AdminPageVersionsService from "cms/back-office/services/AdminPageVersionsService";
import ThemeBackOfficeProvider from "cms/back-office/theme/ThemeBackOfficeProvider";
import CmsHooks, { HOOKS } from "cms/utils/CmsHooks";
import { createQueryParams } from "cms/utils/urlUtils";
import { useAreaList } from "services/area.service";
import { useEstablishmentList } from "services/establishments.service";
import CreateEstablishment from "bo/CreateEstablishment";
import EditEstablishment from "bo/EditEstablishment";
var useStyles = makeStyles(function () { return ({
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1
    },
    addUserBtn: {
        borderRadius: 5,
        flex: 1
    }
}); });
var ManageEstablishments = function (_a) {
    var history = _a.history;
    var classes = useStyles();
    var keycloak = useKeycloak().keycloak;
    var connectedUserId = keycloak.subject;
    var canUpdateManagerEstablishment = keycloak.hasResourceRole("update_establishment_of_manager", "arpavie-api");
    var canUpdateAreaEstablishments = keycloak.hasResourceRole("update_establishment_of_area", "arpavie-api");
    var canUpdateEstablishments = keycloak.hasResourceRole("update_establishment", "arpavie-api");
    var canCreateEstablishments = keycloak.hasResourceRole("create_establishment", "arpavie-api");
    var rowsPerPageOptions = [10, 25, 50];
    var establishmentsPagination = useEstablishmentList().data;
    var _b = (establishmentsPagination || {}).data, allEstablishments = _b === void 0 ? [] : _b;
    var areasPagination = useAreaList().data;
    var _c = (areasPagination || {}).data, areas = _c === void 0 ? [] : _c;
    var _d = React.useState([]), cmsPageVersions = _d[0], setCmsPageVersions = _d[1];
    var getPageVersions = React.useCallback(function (pageIds) {
        return AdminPageVersionsService.find({
            params: {
                join: ["page"],
                filter: ["pageId||in||" + pageIds.join(",")],
                per_page: 200
            }
        }).then(setCmsPageVersions);
    }, []);
    var _e = React.useState(""), search = _e[0], setSearch = _e[1];
    var establishments = React.useMemo(function () {
        if (search.length < 3) {
            return allEstablishments;
        }
        return allEstablishments.filter(function (establishment) {
            var name = establishment.name, type = establishment.type, address = establishment.address;
            var city = address.city, street = address.street;
            return [name, type, city, street].some(function (field) { return field.toLocaleLowerCase().includes(search.toLocaleLowerCase()); });
        });
    }, [allEstablishments, search]);
    var _f = useState({
        order: "desc",
        sortBy: "id"
    }), sorting = _f[0], setSorting = _f[1];
    var _g = useState({ page: 0, rowsPerPage: 10 }), pagination = _g[0], setPagination = _g[1];
    var _h = useState(), currentEstablishment = _h[0], setCurrentEstablishment = _h[1];
    var _j = useState(false), openEstablishmentFormModal = _j[0], setOpenEstablishmentFormModal = _j[1];
    var order = sorting.order, sortBy = sorting.sortBy;
    var page = pagination.page, rowsPerPage = pagination.rowsPerPage;
    var totalRows = useMemo(function () { return (Array.isArray(establishments) ? establishments.length : 0); }, [
        establishments
    ]);
    var sortedEstablishments = useMemo(function () { return (Array.isArray(establishments) ? orderBy(establishments, [sorting.sortBy], [sorting.order]) : []); }, [establishments, sorting]);
    var currentEstablishments = useMemo(function () {
        return Array.isArray(sortedEstablishments)
            ? sortedEstablishments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : [];
    }, [page, rowsPerPage, sortedEstablishments]);
    React.useEffect(function () {
        var pageIds = currentEstablishments.map(function (establishment) { return establishment.cmsPageId; });
        if (pageIds.length > 0) {
            getPageVersions(pageIds);
        }
    }, [currentEstablishments, getPageVersions]);
    var handleSearchChange = function (event) {
        setSearch(event.target.value);
    };
    var handleChangeSort = function (event, key) {
        if (sortBy !== key) {
            setSorting({
                order: "asc",
                sortBy: key
            });
        }
        else {
            setSorting({
                order: order === "asc" ? "desc" : "asc",
                sortBy: key
            });
        }
    };
    var handleChangePage = useCallback(function (event, newPage) {
        setPagination({
            rowsPerPage: rowsPerPage,
            page: newPage
        });
    }, [rowsPerPage, setPagination]);
    var handleChangeRowsPerPage = useCallback(function (event) {
        setPagination({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0
        });
    }, [setPagination]);
    var handleOpenCreateEstablishmentModal = function () {
        setOpenEstablishmentFormModal(true);
    };
    var handleOpenEditEstablishmentModal = function (establishment) {
        setCurrentEstablishment(establishment);
        setOpenEstablishmentFormModal(true);
    };
    var handleCloseEstablishmentFormModal = React.useCallback(function () {
        setCurrentEstablishment();
        setOpenEstablishmentFormModal(false);
    }, []);
    var createSortHandler = function (property) { return function (event) {
        handleChangeSort(event, property);
    }; };
    var headCells = [
        { id: "name", sortable: true, label: "Nom", render: function (row) { return row.name; } },
        { id: "type", sortable: true, label: "Type", render: function (row) { return row.type; } },
        {
            id: "street",
            label: "Adresse",
            render: function (row) { var _a, _b; return ((_a = row.address) === null || _a === void 0 ? void 0 : _a.streetNb) + " " + ((_b = row.address) === null || _b === void 0 ? void 0 : _b.street); }
        },
        {
            id: "address.zipCode",
            sortable: true,
            label: "Code Postal",
            render: function (row) { var _a; return "" + ((_a = row.address) === null || _a === void 0 ? void 0 : _a.zipCode); }
        },
        {
            id: "address.city",
            sortable: true,
            label: "Ville",
            render: function (row) { var _a; return "" + ((_a = row.address) === null || _a === void 0 ? void 0 : _a.city); }
        },
        {
            id: "areaid",
            sortable: true,
            label: "Territoire",
            render: function (row) { var _a; return (_a = areas.find(function (area) { return area.id === row.areaId; })) === null || _a === void 0 ? void 0 : _a.name; }
        },
        {
            id: "edit",
            label: "",
            render: function (row) {
                var cmsPageId = row.cmsPageId, establishmentAreaId = row.areaId, manager = row.manager;
                var establishmentManagerId = (manager || {}).id;
                var version = cmsPageId && cmsPageVersions.find(function (v) { return v.pageId === cmsPageId; });
                var _a = (version === null || version === void 0 ? void 0 : version.page) || {}, pageId = _a.id, siteId = _a.siteId;
                var area = areas.find(function (area) { return area.id === establishmentAreaId; });
                var responsible = (area || {}).responsible;
                var canUpdate = canUpdateEstablishments ||
                    (canUpdateAreaEstablishments && responsible === connectedUserId) ||
                    (canUpdateManagerEstablishment && establishmentManagerId === connectedUserId);
                return (_jsxs(_Fragment, { children: [canUpdate && (_jsx(IconButton, __assign({ sx: { p: 0.5, fontSize: "1.1rem" }, onClick: function () { return handleOpenEditEstablishmentModal(row); } }, { children: _jsx(Icon, { icon: "pen", title: "Modifier l'\u00E9tablissement" }, void 0) }), void 0)), version && (_jsx(IconButton, __assign({ sx: { p: 0.5, fontSize: "1.1rem", ml: 1 }, onClick: function () {
                                var _a;
                                history.push(CmsHooks.getHook(HOOKS.Bo_AdminWebsitePath) + "?" + createQueryParams((_a = {},
                                    _a[queryParamSiteKey] = siteId,
                                    _a[queryParamPageKey] = pageId,
                                    _a)));
                            } }, { children: _jsx(Icon, { icon: "eye", title: "Voir la fiche \u00E9tablissement" }, void 0) }), void 0))] }, void 0));
            }
        }
    ];
    return (_jsxs(_Fragment, { children: [_jsxs(Grid, __assign({ container: true, alignItems: "center", justifyContent: "space-between", sx: { mb: 2 } }, { children: [_jsx(Grid, __assign({ item: true, xs: "auto" }, { children: _jsx(Input, { placeholder: "Filtrer les \u00E9tablissements", disableUnderline: true, onChange: handleSearchChange, endAdornment: _jsx(InputAdornment, __assign({ position: "end" }, { children: _jsx(IconButton, __assign({ size: "large" }, { children: _jsx(Search, {}, void 0) }), void 0) }), void 0) }, void 0) }), void 0), canCreateEstablishments && (_jsx(Grid, __assign({ item: true, xs: "auto" }, { children: _jsxs(Button, __assign({ className: classes.addUserBtn, color: "secondary", variant: "contained", size: "small", onClick: handleOpenCreateEstablishmentModal }, { children: [_jsx(Icon, { icon: "hotel" }, void 0), _jsx(Box, __assign({ sx: { ml: 1 } }, { children: "Nouvel \u00E9tablissement" }), void 0)] }), void 0) }), void 0))] }), void 0), !establishments && (_jsx(Box, __assign({ sx: { my: 2 } }, { children: _jsx(Loader, {}, void 0) }), void 0)), establishments && establishments.length > 0 ? (_jsxs(_Fragment, { children: [_jsxs(Table, { children: [_jsx(TableHead, { children: _jsx(TableRow, { children: headCells.map(function (headCell) { return (_jsx(TableCell, __assign({ sx: { fontWeight: 600, fontSize: "1rem" } }, { children: _jsxs(TableSortLabel, __assign({ hideSortIcon: !headCell.sortable, active: sortBy === headCell.id, direction: order, sx: {
                                                color: "inherit",
                                                lineHeight: "normal"
                                            }, onClick: headCell.sortable ? createSortHandler(headCell.id) : undefined }, { children: [headCell.label, sortBy === headCell.id ? (_jsx("span", __assign({ className: classes.visuallyHidden }, { children: order === "desc" ? "sorted descending" : "sorted ascending" }), void 0)) : null] }), void 0) }), headCell.id)); }) }, void 0) }, void 0), _jsx(TableBody, { children: currentEstablishments.map(function (data) { return (_jsx(TableRow, { children: headCells.map(function (attribut, index) { return (_jsx(TableCell, { children: attribut.render(data) }, "" + (attribut + index.toString()))); }) }, data.id)); }) }, void 0)] }, void 0), pagination !== null && (_jsx(TablePagination, { labelRowsPerPage: "\u00C9tablissements par page", rowsPerPageOptions: rowsPerPageOptions, component: "div", count: totalRows, rowsPerPage: rowsPerPage, page: page, backIconButtonProps: {
                            "aria-label": "Pages suivantes"
                        }, nextIconButtonProps: {
                            "aria-label": "Pages précédentes"
                        }, onPageChange: handleChangePage, onRowsPerPageChange: handleChangeRowsPerPage, labelDisplayedRows: function () { return "Page " + (page + 1) + " sur " + Math.ceil(totalRows / rowsPerPage); } }, void 0))] }, void 0)) : (establishments && (_jsx(Typography, __assign({ variant: "h5", sx: { fontSize: "1rem", fontWeight: 600, my: 2 } }, { children: "Aucun \u00E9tablissement trouv\u00E9" }), void 0))), _jsx(StyledEngineProvider, __assign({ injectFirst: true }, { children: _jsx(ThemeBackOfficeProvider, { children: _jsx(Modal
                    // @ts-ignore
                    , __assign({ 
                        // @ts-ignore
                        open: openEstablishmentFormModal, size: "md", title: (currentEstablishment ? "Modifier" : "Ajouter") + " un \u00E9tablissement", onClose: handleCloseEstablishmentFormModal }, { children: currentEstablishment ? (_jsx(EditEstablishment, { establishment: currentEstablishment, onValidate: handleCloseEstablishmentFormModal, onCancel: handleCloseEstablishmentFormModal }, void 0)) : (_jsx(CreateEstablishment, { onValidate: handleCloseEstablishmentFormModal, onCancel: handleCloseEstablishmentFormModal }, void 0)) }), void 0) }, void 0) }), void 0)] }, void 0));
};
export default withRouter(ManageEstablishments);
