import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import AdminSitesService from "cms/back-office/services/AdminSitesService";
import AdminPagesService from "cms/back-office/services/AdminPagesService";
import AdminPageVersionsService from "cms/back-office/services/AdminPageVersionsService";
import { createQueryParams, getQueryParams } from "cms/utils/urlUtils";
import LoadingPage from "cms/back-office/components/Layout/LoadingPage";
import CmsHooks, { HOOKS } from "cms/utils/CmsHooks";
import versionStatuses from "cms/enums/versionStatuses";

const AdminWebsiteContext = React.createContext({
  currentSite: null,
  setCurrentSite: () => null,
  currentSiteTheme: {},
  allSites: [],
  currentPage: null,
  setCurrentPage: () => null,
  refreshSite: () => null,
  currentPageVersion: null,
  setCurrentPageVersion: () => null,
  pageVersions: [],
  refreshPageVersions: () => null,
  goToHomePage: () => null,
  versionIsCanceled: false,
  setVersionIsCanceled: () => null,
  editorRefreshVersion: false,
  setEditorRefreshVersion: () => null,
  editorValueChange: false,
  setEditorValueChange: () => null,
  pageChanges: false,
  setPageChanges: () => null,
  versionHasChanged: false,
  setVersionHasChanged: () => null
});

export default AdminWebsiteContext;

export const queryParamSiteKey = "site";
export const queryParamPageKey = "page";
export const queryParamVersionKey = "version";

const currentSiteStorageKey = "currentSite";

export const AdminWebsiteContextProvider = withRouter(props => {
  const { location, history, children } = props;

  const { pathname } = location;

  const { push: historyPush } = history;

  const queryParams = getQueryParams();
  const {
    [queryParamSiteKey]: siteId,
    [queryParamPageKey]: queryParamPage,
    [queryParamVersionKey]: queryParamVersion
  } = queryParams;

  const [allSites, setAllSites] = useState([]);
  const [currentSite, setCurrentSite] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [pageVersions, setPageVersions] = useState([]);
  const [currentPageVersion, setCurrentPageVersion] = useState(null);
  const [versionIsCanceled, setVersionIsCanceled] = useState(false);
  const [editorValueChange, setEditorValueChange] = useState(false);
  const [pageChanges, setPageChanges] = useState([]);
  const [versionHasChanged, setVersionHasChanged] = useState(false);

  const { id: currentPageVersionId } = currentPageVersion || {};

  const currentSiteTheme = React.useMemo(() => {
    const projectTheme = CmsHooks.getHook(HOOKS.THEME);
    const getTheme = CmsHooks.getHook(HOOKS.GET_THEME);
    return (typeof getTheme === "function" && getTheme({ site: currentSite })) || projectTheme || createTheme();
  }, [currentSite]);

  const refreshSite = React.useCallback(() => {
    if (currentSite) {
      return AdminSitesService.get(currentSite.id).then(site => {
        setCurrentSite(site);
        return site;
      });
    }
    return new Promise(resolve => resolve({}));
  }, [currentSite]);

  const refreshPageVersions = React.useCallback(() => {
    if (currentSite && currentPage) {
      return AdminPageVersionsService.getPageVersions(currentPage.id).then(versions => {
        versions.sort(({ status: status1 }, { status: status2 }) => {
          if (status1 === status2 || ![status1, status2].includes(versionStatuses.ARCHIVED)) {
            return 0;
          }
          return status1 === versionStatuses.ARCHIVED ? 1 : -1;
        });
        setPageVersions(versions);
        return versions;
      });
    }
    return new Promise(resolve => resolve([]));
  }, [currentSite, currentPage]);

  const handleChangeSite = React.useCallback(
    site => {
      const { id } = site;
      localStorage.setItem(currentSiteStorageKey, id);
      historyPush(
        `${pathname}?${createQueryParams({
          [queryParamSiteKey]: id
        })}`
      );
    },
    [historyPush, pathname]
  );

  const handleChangePage = React.useCallback(
    page => {
      const { id } = page;
      historyPush(
        `${pathname}?${createQueryParams({
          [queryParamSiteKey]: siteId,
          [queryParamPageKey]: id
        })}`
      );
    },
    [historyPush, pathname, siteId]
  );

  const handleChangePageVersion = React.useCallback(
    (pageVersion = {}) => {
      const { id } = pageVersion;
      if (currentPageVersionId !== id) {
        historyPush(
          `${pathname}?${createQueryParams({
            [queryParamSiteKey]: siteId,
            [queryParamPageKey]: queryParamPage,
            [queryParamVersionKey]: id
          })}`
        );
      } else {
        setCurrentPageVersion(pageVersion);
      }
    },
    [currentPageVersionId, historyPush, pathname, queryParamPage, siteId]
  );

  const goToHomePage = React.useCallback(() => {
    if (currentSite) {
      AdminPageVersionsService.find({
        params: {
          join: ["page"],
          filter: [`path||eq||/`, `page.siteId||eq||${currentSite.id}`, `page.parentId||isnull`]
        }
      }).then(versions => {
        const [version] = versions;
        if (version) {
          handleChangePage(version.page);
        }
      });
    }
  }, [currentSite, handleChangePage]);

  // Load Sites
  useEffect(() => {
    AdminSitesService.find({
      params: {
        sort: "name,ASC"
      }
    }).then(setAllSites);
  }, []);

  // Set current Site
  useEffect(() => {
    if (allSites.length) {
      const id = siteId || localStorage.getItem(currentSiteStorageKey);
      let site = allSites.find(s => s.id === id);
      if (site) {
        setCurrentSite(site);
      } else {
        site = allSites.find(s => s.name === "PADVERT") || allSites[0];
        handleChangeSite(site);
      }
    }
  }, [allSites, handleChangeSite, siteId]);

  // Get current Page from query param
  // Redirect to home if not found or no param
  useEffect(() => {
    if (currentPageVersion) {
      setPageChanges([]);
    }

    if (!queryParamPage) {
      goToHomePage();
    }
  }, [currentPageVersion, currentSite, goToHomePage, queryParamPage]);

  useEffect(() => {
    if (queryParamPage) {
      AdminPagesService.get(queryParamPage)
        .then(setCurrentPage)
        .catch(() => goToHomePage());
    }
  }, [goToHomePage, queryParamPage]);

  // Refresh versions when current page change
  useEffect(() => {
    refreshPageVersions();
  }, [currentSite, refreshPageVersions]);

  // Set current version
  useEffect(() => {
    if (pageVersions.length) {
      const version = pageVersions.find(v => v.id === queryParamVersion);
      if (version && (!currentPageVersion || version.id !== currentPageVersion.id)) {
        setCurrentPageVersion(version);
      } else if (!version) {
        handleChangePageVersion(pageVersions[0]);
      }
    }
  }, [currentPageVersion, handleChangePageVersion, pageVersions, queryParamVersion]);

  if (!currentSite) {
    return <LoadingPage title="Chargement des sites" />;
  }

  return (
    <AdminWebsiteContext.Provider
      value={{
        currentSite,
        setCurrentSite: handleChangeSite,
        currentSiteTheme,
        refreshSite,
        allSites,
        currentPage,
        setCurrentPage: handleChangePage,
        currentPageVersion,
        setCurrentPageVersion: handleChangePageVersion,
        pageVersions,
        refreshPageVersions,
        goToHomePage,
        versionIsCanceled,
        setVersionIsCanceled,
        editorValueChange,
        setEditorValueChange,
        pageChanges,
        setPageChanges,
        versionHasChanged,
        setVersionHasChanged
      }}
    >
      {children}
    </AdminWebsiteContext.Provider>
  );
});
